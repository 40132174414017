import styled from "styled-components";
import { Grid} from "@mui/material";

export const GridContainer = styled(Grid)(() => ({
  display: 'flex',
  flexDirection: 'column',
}))
GridContainer.defaultProps = {
  container: true,
  columnSpacing: 2,
  rowSpacing: 2,
}

export const СertifiedСounselor = styled.div(() => ({
  marginTop: '66px'
}))
