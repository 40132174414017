import React, { useMemo } from "react";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { parseStringToBoolean } from "utils/parse";
import { PregnancyModel } from "models";
import { CaseNumber } from "pages/PdfPages/components";
import RadioButtons from "pages/PdfPages/components/RadioButtons/RadioButtons";

export const PageFour: React.FC<{ data: GeneralForm | null }> = ({ data }) => {
  const hasPregnant = data?.["3"]?.pregnancy.some((p) =>
    parseStringToBoolean(p.isPregnant),
  );

  const membersWIthPlaceholders = useMemo(() => {
    // need to display min 6 rows for members, with current members and fill with empty placeholders
    const membersCount = data?.["2"]?.members?.length ?? 0;
    const MIN_ROW_LENGTH = 6;
    if (membersCount < MIN_ROW_LENGTH) {
      const existingMembers = data?.["2"]?.members || [];
      const memberPlaceholder = {
        birthday: "",
        firstName: "",
        id: "",
        lastName: "",
        materialStatus: "",
        middleName: "",
        relationship: "",
      };

      return Array.from(
        { length: MIN_ROW_LENGTH },
        (_, index) => existingMembers[index] || memberPlaceholder,
      );
    }
    return data?.["2"]?.members;
  }, [data]);

  const isAnyonePregnant = data?.[3]?.pregnancy?.some((pm) =>
    parseStringToBoolean(pm.isPregnant),
  );

  const renderPregnancyRow = (item: PregnancyModel | undefined) => {
    return (
      <tr className="h-8" key={item?.id ?? ""}>
        <td className="text-xs">{item?.name ?? ""}</td>
        <td className="text-center text-xs">{item?.birthday ?? ""}</td>
        <td className="text-center text-xs">
          {item?.expectedBabiesCount ?? ""}
        </td>
      </tr>
    );
  };

  return (
    <div className="container-fluid pt-12">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={data?.["1"]?.caseNumber} />
        <div className="array_container border border-gray-400">
          <div className="flex space-x-8 items-center p-2">
            <ul className="w-2/5">
              <h2 className="font-bold text-base">
                2. List all household members
              </h2>
              <li className="pl-4 text-xs">• Yourself</li>
              <li className="pl-4 text-xs">• Your spouse</li>
              <li className="pl-4 text-xs">
                • Your children under 19 that live with you
              </li>
              <li className="pl-4 text-xs">
                • Your spouse's children under 19 that live with you
              </li>
            </ul>
            <ul className="w-2/5">
              <li className="text-xs">
                • Your unmarried partner, if you have children together
              </li>
              <li className="text-xs">
                • Anyone you include on your tax return, even if that person
                does not live with you
              </li>
              <li className="text-xs">
                • Anyone else under 19 that you take care of and that lives with
                you
              </li>
            </ul>
          </div>
          <table className="table">
            <thead>
              <tr className="text-xs">
                <th className="text-center text-xs">First name</th>
                <th className="text-center text-xs">MI</th>
                <th className="text-center text-xs">Last Name</th>
                <th className="text-center text-xs">Date of birth</th>
                <th className="text-center text-xs">
                  Marital status <br />{" "}
                  <span className="small_text">
                    (Married, Legally <br /> Separated, Divorced, <br /> Never
                    Married, Widowed)
                  </span>
                </th>
                <th className="text-center text-xs">
                  Relationship to you{" "}
                  <span className="small_text">
                    {" "}
                    <br />
                    (Examples: Self, Spouse, <br /> Child, Step Child, not
                    related)
                  </span>
                </th>
              </tr>
            </thead>
            <tbody>
              {membersWIthPlaceholders?.map((member, index) => (
                <tr className={"h-8"} key={index}>
                  <td className="text-center text-xs">{member.firstName}</td>
                  <td className="text-center text-xs">{member.middleName}</td>
                  <td className="text-center text-xs">{member.lastName}</td>
                  <td className="text-center text-xs">{member.birthday}</td>
                  <td className="text-center text-xs">
                    {member.materialStatus}
                  </td>
                  <td className="text-center text-xs">{member.relationship}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div className="p-2 border border-gray-400 bg-[#706f6f3b]">
          <h2 className="font-bold text-base">
            3. Are you requesting coverage for someone who does not currently
            have coverage or are you adding a new household member?
          </h2>
          <div className="flex space-x-4 pl-4 text-xs">
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="radio2"
                value="1"
              />
              <span className="ml-2 text-xs">
                Yes* - who? (person requesting coverage)
              </span>{" "}
              <input
                type="text"
                className="input_case_number border-b border-gray-400"
              />
            </label>
            <label className="inline-flex items-center">
              <input
                type="radio"
                className="form-radio"
                name="radio2"
                value="2"
                defaultChecked={true}
              />
              <span className="ml-2 text-xs">No</span>
            </label>
          </div>
          <p className="text-xs">
            *Complete Appendix A for each new household member or new applicant
            requesting coverage, or call your county agency or DHS Health Care
            Consumer Support to add a new person to your household. See the
            Agency Addresses (Attachment B) to get the address and phone number
            for your servicing agency.{" "}
          </p>
        </div>
        <div className="border border-gray-400 bg-gray-100">
          <div className="bg-[#706f6f3b]">
            <div className="px-2 pt-2">
              <h2 className="font-bold text-base">4. Is anyone pregnant?</h2>
            </div>
            <div className="flex space-x-4 px-4 py-2">
              <RadioButtons
                items={[
                  {
                    checked: parseStringToBoolean(hasPregnant),
                    label: "Yes* - who? (person requesting coverage)",
                  },
                  {
                    checked: !parseStringToBoolean(hasPregnant),
                    label: "No",
                  },
                ]}
              />
            </div>
          </div>
          <table>
            <thead>
              <tr className="text-xs text-left">
                <th>NAME OF HOUSEHOLD MEMBER WHO HAD THIS CHANGE</th>
                <th>DUE DATE</th>
                <th>HOW MANY BABIES ARE EXPECTED?</th>
              </tr>
            </thead>
            <tbody>
              {isAnyonePregnant
                ? data?.[3]?.pregnancy.map((member) => {
                    return parseStringToBoolean(member.isPregnant)
                      ? renderPregnancyRow(member)
                      : null;
                  })
                : renderPregnancyRow(undefined)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
