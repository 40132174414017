import React from "react";
import { useFormik } from "formik";
import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import { FormActionButtons, FormikBirthdayPicker, Input, } from "components";
import { AssisterModel } from "models";

import { Form, FormContent } from "../Forms.css";
import { GridContainer, СertifiedСounselor, } from "./AssisterForm.css";

interface SignFormProps {
  onBack: () => void;
  defaultValues: AssisterModel;
  onSubmit: (values: AssisterModel) => void;
}

export const AssisterForm = ({
                               onBack,
                               defaultValues,
                               onSubmit,
                             }: SignFormProps) => {
  const {t} = useTranslation();
  const formik = useFormik<AssisterModel>({
    enableReinitialize: true,
    initialValues: defaultValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return (
    <Form onSubmit={formik.handleSubmit} autoComplete="off">
      <FormContent>
        <СertifiedСounselor>
          <GridContainer>
            <Grid item xs={12} md={4} marginTop={3}>
              <FormikBirthdayPicker
                label={t("assisters_start_date")}
                name="applicationStartDate"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={4} marginTop={3}>
              <Input
                label={t("assisters_name_applicant")}
                name="nameApplicant"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={4} marginTop={3}>
              <Input
                label={t("assisters_name_assister")}
                name="nameAssister"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("assisters_phone_assister")}
                name="phoneAssister"
                formik={formik}
                inputProps={{readOnly: true}}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("assisters_organization_name")}
                name="organizationName"
                formik={formik}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input
                label={t("assisters_id")}
                name="idAssister"
                formik={formik}
              />
            </Grid>
          </GridContainer>
        </СertifiedСounselor>
      </FormContent>
      <FormActionButtons disableBackButton={false} onBack={onBack}/>
    </Form>
  );
};
