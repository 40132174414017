import { v4 as uuidv4 } from "uuid";
import i18n from "i18n";
import {
  AdjustmentIncomeModel,
  AssisterModel,
  CurrentJobModel,
  DetailJobMemberModel,
  FollowingQuestionsModel,
  HealthCoverageModel,
  HouseholdMemberModel,
  IncomeQuestionModel,
  IncomeTypeModel,
  OtherIncomeSourceModel,
  PregnancyModel,
  RenewingCoverageModel,
  SeasonalJobModel,
  SelfEmploymentModel,
  TaxFillingModel,
} from "models";
import { CurrentJobEnum, RelationshipEnum } from "enums";
import { GeneralForm } from "./types";
import { calculatePaiMemberAnnualIncome } from "./utils";
import { PAIFormFormType } from "components/pages/DhsPage/Forms/PAIForm/PAIForm";
import { getCurrentStrDate } from "utils/dates";

export const useGetDefaultFormValues = (data: GeneralForm | null) => {
  /** 1.APPLICANT  FORM */
  const getDefaultApplicantFormValues = () => {
    const applicantValues = data?.[1];

    const applicantInitialValues = {
      caseNumber: "",
      firstName: "",
      middleName: "",
      lastName: "",
      birthday: "",
      materialStatus: "",
      phoneNumber: "",
      otherPhoneNumber: "",
      streetAddress: "",
      city: "",
      state: "",
      zipCode: "",
      county: "",

      alternativeStreetAddress: "",
      alternativeCity: "",
      alternativeState: "",
      alternativeZipCode: "",
      alternativeCounty: "",
      phoneType: "cell",
      otherPhoneType: "cell",
      email: "",
      contactViaEmail: false,
      allowTextMessage: true,
    };
    return applicantValues ?? applicantInitialValues;
  };

  const defaultApplicantMember = getDefaultApplicantFormValues();

  /** 1.APPLICANT FORM ENDS */

  /** 2.DEFAULT HOUSEHOLD FORM */
  const getDefaultHouseholdMembersValues = () => {
    const applicantValues = data?.[1];

    const memberListValues = data?.[2]?.members;
    const hasMembers = !!memberListValues?.length;
    const applicantValue = {
      id: uuidv4(),
      firstName: applicantValues?.firstName,
      middleName: applicantValues?.middleName,
      lastName: applicantValues?.lastName,
      birthday: applicantValues?.birthday,
      materialStatus: i18n.t(applicantValues?.materialStatus),
      relationship: i18n.t(RelationshipEnum.SELF),
    } as HouseholdMemberModel;

    return hasMembers ? memberListValues : [applicantValue];
  };
  const defaultHouseholdMembers = getDefaultHouseholdMembersValues();

  /** DEFAULT HOUSEHOLD FORM ENDS*/

  /** 3.DEFAULT PREGNANCY FORM */
  const getDefaultPregnancyValues = () => {
    const householdMembers = data?.[2]?.members;
    const pregnancyMembers = data?.[3]?.pregnancy;

    const pregnancyListFromHouseholdMembers = householdMembers?.map((m) => {
      return {
        id: m.id,
        name: `${m.firstName} ${m.lastName}`,
        expectedBabiesCount: null,
        isPregnant: false,
      };
    });
    return (
      pregnancyMembers ??
      (pregnancyListFromHouseholdMembers as PregnancyModel[])
    );
  };

  const defaultPregnancyMembers = getDefaultPregnancyValues();

  /** DEFAULT PREGNANCY FORM ENDS */

  /** 4.DEFAULT TAX FILLING FORM */
  const getDefaultTaxFillingValues = () => {
    const householdMembers = data?.[2]?.members;
    const taxFillingMembers = data?.[4]?.taxFillingMembers;

    const taxFillingFromHouseholdMembers = householdMembers?.map((tm) => {
      return {
        id: tm.id,
        name: `${tm.firstName} ${tm.lastName}`,
        isMarriedFilingJointly: null,
        claimantOfDependent: "",
        outsideHouseholdTaxDependent: false,
        isClaimedByNoncustodialParent: false,
      };
    });
    return (
      taxFillingMembers ?? (taxFillingFromHouseholdMembers as TaxFillingModel[])
    );
  };

  const defaultTaxFillingMembers = getDefaultTaxFillingValues();

  /** DEFAULT TAX FILLING FORM ENDS */

  /** 5.DEFAULT INCOME QUESTION FORM */
  const getDefaultIncomeQuestionValues = () => {
    const householdMembers = data?.[2]?.members;
    const incomeMembers = data?.[5]?.incomeMembers;

    const incomeFromHouseholdMembers = householdMembers?.map((qm) => {
      return {
        id: qm.id,
        name: `${qm.firstName} ${qm.lastName}`,
        hasIncome: false,
      };
    });
    return (
      incomeMembers ?? (incomeFromHouseholdMembers as IncomeQuestionModel[])
    );
  };

  const defaultIncomeQuestionMembers = getDefaultIncomeQuestionValues();

  /** DEFAULT INCOME QUESTION FORM ENDS */

  /** 6.DEFAULT INCOME TYPE FORM */
  const getDefaultIncomeTypesValues = () => {
    const incomeMembers = data?.[5]?.incomeMembers;
    const membersWithIncome = incomeMembers?.filter((i) =>
      JSON.parse(String(i.hasIncome)),
    );
    const incomeTypesMembers = data?.[6]?.incomeTypeMembers;

    const incomeFromHouseholdMembers = membersWithIncome?.map((qt) => {
      return {
        id: qt.id,
        name: qt.name,
        currentOrPartJob: i18n.t(CurrentJobEnum.NO),
        hasSeasonalJob: false,
        isSelfEmployed: false,
        otherIncomeSource: i18n.t("no"),
      };
    });

    return (
      incomeTypesMembers ?? (incomeFromHouseholdMembers as IncomeTypeModel[])
    );
  };

  const defaultIncomeTypeMembers = getDefaultIncomeTypesValues();

  /** DEFAULT INCOME TYPE FORM ENDS */

  /** 7. DEFAULT DETAIL MEMBER JOB FORM */

  const getDefaultDetailJobMembers = () => {
    const incomeMembers = data?.[6]?.incomeTypeMembers;
    const detailJobMembers = data?.[7]?.detailJobMembers;

    const defaultDetailJobMembers = incomeMembers
      ?.map((dm) => {
        const hasCurrentJob = dm.currentOrPartJob !== i18n.t(CurrentJobEnum.NO);
        const hasSeasonalJob = JSON.parse(String(dm.hasSeasonalJob));
        const isSelfEmployment = JSON.parse(String(dm.isSelfEmployed));
        const hasOtherIncomeSource = i18n.t("no") !== dm.otherIncomeSource;

        if (
          !hasCurrentJob &&
          !hasSeasonalJob &&
          !isSelfEmployment &&
          !hasOtherIncomeSource
        )
          return null;

        const fillDefaultCurrentJobsByAnswer = {
          [i18n.t(CurrentJobEnum.NO)]: 0,
          [i18n.t(CurrentJobEnum.YES_ONE_JOB)]: 1,
          [i18n.t(CurrentJobEnum.YES_TWO_JOBS)]: 2,
        };

        const jobsQuantity =
          //@ts-ignore
          fillDefaultCurrentJobsByAnswer[dm.currentOrPartJob];

        const currentJobFields: CurrentJobModel = {
          employerName: "",
          employerEin: "",
          employerStreetAddress: "",
          employerCity: "",
          employerZipCode: "",
          employerState: "",
          wage: "",
          hoursPerWeek: null,
          amount: null,
        };
        const seasonalJobFields: SeasonalJobModel[] = [
          {
            employerName: "",
            employerEin: "",
            employerStreetAddress: "",
            employerCity: "",
            employerZipCode: "",
            employerState: "",
            seasonalIncome: null,
            expectedUnemployment: null,
          },
        ];
        const selfEmploymentFields: SelfEmploymentModel = {
          selfEmployedName: "",
          typeOfBusiness: "",
          businessStartDate: "",
          incomeAmount: null,
          lossAmount: null,
        };
        const otherIncomeSourceFields: OtherIncomeSourceModel = {
          typeOfIncome: i18n.t(dm.otherIncomeSource),
          howOftenReceived: "",
          amount: null,
          notTaxableInterestIncome: null,
          incomes: [
            { type: "", amount: null, frequency: "" },
            { type: "", amount: null, frequency: "" },
          ],
        };

        return {
          id: dm.id,
          name: dm.name,
          ...(hasCurrentJob && {
            currentJobs: Array(jobsQuantity).fill(currentJobFields),
          }),
          ...(hasSeasonalJob && { seasonalJobs: seasonalJobFields }),
          ...(isSelfEmployment && { selfEmployment: selfEmploymentFields }),
          ...(hasOtherIncomeSource && {
            otherIncomeSource: otherIncomeSourceFields,
          }),
        };
      })
      .filter((item) => item !== null);

    return (
      detailJobMembers ?? (defaultDetailJobMembers as DetailJobMemberModel[])
    );
  };
  const defaultDetailJobMembers = getDefaultDetailJobMembers();

  /** DEFAULT DETAIL MEMBER JOB FORM ENDS */

  /** 8. ADJUSTMENT INCOME MEMBER FORM */

  const getDefaultAdjustmentIncomeValues = () => {
    const incomeMembers = data?.[5]?.incomeMembers;
    const taxFillingMembers = data?.[8]?.adjustmentIncomeMembers;

    const adjustmentIncomeFromIncomeMembers = incomeMembers?.map((im) => {
      return {
        id: im.id,
        name: im.name,
        type: "",
        yearlyAmount: null,
        hasIncomeAdjustment: false,
      };
    });
    return (
      taxFillingMembers ??
      (adjustmentIncomeFromIncomeMembers as AdjustmentIncomeModel[])
    );
  };

  const defaultAdjustmentIncomeMembers = getDefaultAdjustmentIncomeValues();

  /** 9. PAI MEMBERS FORM **/

  const getDefaultPaiValues = () => {
    const paiForm = data?.[9];
    const detailJobMembers = data?.[7]?.detailJobMembers;
    const adjustmentsIncomeMembers = data?.[8]?.adjustmentIncomeMembers;

    const paiFromIncomeMembers = detailJobMembers?.map((pm) => {
      const { expectedIncome, adjustmentsIncome, projectedAnnualIncome } =
        calculatePaiMemberAnnualIncome(pm, adjustmentsIncomeMembers ?? []);

      return {
        id: pm.id,
        name: pm.name,
        expectedIncome: expectedIncome,
        adjustmentsIncome: adjustmentsIncome,
        projectedAnnualIncome: projectedAnnualIncome,
      };
    });

    const defaultPaiForm = {
      isSameTotalIncome: "false",
      paiMembers: paiFromIncomeMembers,
    };
    return paiForm ?? (defaultPaiForm as PAIFormFormType);
  };

  const defaultPaiMembers = getDefaultPaiValues();

  /** 9. PAI MEMBERS FORM ENDS**/

  /** 10. HEALTH COVERAGE MEMBER FORM */

  const getDefaultHealthCoverageValues = () => {
    const healthCoverageFromMemberList = data?.[2]?.members;
    const healthCoverageMemberList = data?.[10]?.healthCoverageMembers;

    const adjustmentIncomeFromIncomeMembers = healthCoverageFromMemberList?.map(
      (hc) => {
        return {
          id: hc.id,
          name: `${hc.firstName} ${hc.lastName}`,
          isEnrolledHealthCoverage: false,
          healthCoverage: "",
          policyHolderName: "",
          policyDateOfBirth: "",
          insuranceCompanyName: "",
          startDate: "",
          endDate: "",
          groupNumber: null,
          insurancePolicyName: "",
          policyCoverageList: "",
          policyNumber: null,
        };
      },
    );
    return (
      healthCoverageMemberList ??
      (adjustmentIncomeFromIncomeMembers as HealthCoverageModel[])
    );
  };

  const defaultHealthCoverageMembers = getDefaultHealthCoverageValues();

  /** 10. HEALTH COVERAGE MEMBER FORM ENDS */

  /** 11. FOLLOWING QUESTIONS MEMBER FORM */

  const getDefaultFollowingQuestionsValues = () => {
    const followingQuestionsMembers = data?.[11];

    const followingQuestionDefault = {
      isInJail: "false",
      whoIsInJail: "",
      isDispositionOfCharges: "false",
      whoIsDispositionOfCharges: "",
      isNeedPayingMedical: "false",
      whoIsNeedPayingMedical: "",
      hasHealthConditions: "false",
      whoHasHealthConditions: "",
      isBlindOrDisabled: "false",
      whoIsBlindOrDisabled: "",
      hasDeterminedDisabledSSA: "false",
      whoHasDeterminedDisabledSSA: "",
      isReceivingSSI: "false",
      whoIsReceivingSSI: "",
      isNeedHomeCareHelp: "false",
      whoIsNeedHomeCareHelp: "",
      hasOngoingMedicalBills: "false",
      whoHasOngoingMedicalBills: "",
      isGettingServiceCVT: "false",
      whoIsGettingServiceCVT: "",
      isOutsideOfState: "false",
      whoIsOutsideOfState: "",
      dateLeft: "",
      hasReturnedFromMilitaryDutyTour: "false",
      whoHasReturnedFromMilitaryDutyTour: "",
      lastActiveDate: "",
    };
    return (
      followingQuestionsMembers ??
      (followingQuestionDefault as FollowingQuestionsModel)
    );
  };
  const defaultFollowingQuestionsMembers = getDefaultFollowingQuestionsValues();

  /** 11. FOLLOWING QUESTIONS MEMBER FORM ENDS */

  /** 12 DEFAULT SIGN FORM */
  const getDefaultRenewingCoverage = () => {
    const renewingData = data?.[12];
    const renewingCoverageEmptyData = {
      agreementDuration: "1_year",
    };
    return renewingData ?? (renewingCoverageEmptyData as RenewingCoverageModel);
  };

  const defaultRenewingCoverage = getDefaultRenewingCoverage();

  /** 12. DEFAULT SIGN FORM ENDS */

  /** 13 DEFAULT ASSISTER FORM */
  const getDefaultAssisterValues = () => {
    const applicantData = data?.[1];
    const assisterData = data?.[13];
    const currentDate = getCurrentStrDate();

    const assisterFromApplicant = {
      nameApplicant: `${applicantData?.firstName} ${applicantData?.middleName} ${applicantData?.lastName}`,
      nameAssister: "",
      applicationStartDate: currentDate,
      phoneAssister: "855-566-7873",
      organizationName: "Briva Health",
      idAssister: "",
    };
    return assisterData ?? (assisterFromApplicant as AssisterModel);
  };

  const defaultAssisterValues = getDefaultAssisterValues();


  return {
    defaultApplicantMember,
    defaultHouseholdMembers,
    defaultPregnancyMembers,
    defaultTaxFillingMembers,
    defaultIncomeQuestionMembers,
    defaultIncomeTypeMembers,
    defaultDetailJobMembers,
    defaultAdjustmentIncomeMembers,
    defaultPaiMembers,
    defaultHealthCoverageMembers,
    defaultFollowingQuestionsMembers,
    defaultRenewingCoverage,
    defaultAssisterValues
  };
};
