import React from "react";
import { Grid } from "@mui/material";
import { FormActions, FormikInputField, FormRow } from "components";
import { GridContainer, RemoveFormButton } from "../DetailMemberJobRow.css";

interface SeasonalJobProps {
  index: number;
  seasonalIndex: number;
  showDeleteButton: boolean;
  remove: () => void;
}

export const SeasonalJobForm = ({
  index,
  seasonalIndex,
  showDeleteButton,
  remove,
}: SeasonalJobProps) => {
  const renderFormRows = (
    <FormRow>
      <GridContainer>
        <Grid item md={8} xs={12}>
          <FormikInputField
            label="employer_name"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.employerName`}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <FormikInputField
            label="employer_EIN"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.employerEin`}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormikInputField
            label="employer_street_address"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.employerStreetAddress`}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormikInputField
            label="city"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.employerCity`}
          />
        </Grid>
        <Grid item md={2} xs={12}>
          <FormikInputField
            label="state"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.employerState`}
          />
        </Grid>
        <Grid item md={1} xs={12}>
          <FormikInputField
            label="zip_code"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.employerZipCode`}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormikInputField
            type="number"
            label="total_seasonal_income_12_month"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.seasonalIncome`}
          />
        </Grid>
        <Grid item md={3} xs={12}>
          <FormikInputField
            type="number"
            label="total_expected_unemployment_12_month"
            name={`detailJobMembers.${index}.seasonalJobs.${seasonalIndex}.expectedUnemployment`}
          />
        </Grid>
      </GridContainer>
      {showDeleteButton && (
        <RemoveFormButton variant="contained" type="button" onClick={remove}>
          Remove
        </RemoveFormButton>
      )}
    </FormRow>
  );

  return <FormActions title="seasonal_job_form_title" form={renderFormRows} />;
};
