import { validation } from 'formUtils/validation';
import * as Yup from 'yup';

export const documentTypeOptions = [
  { uuid: 'prove_of_income', name: 'prove_of_income' },
  { uuid: 'immigration_status', name: 'immigration_status' }
]

const documentListTypeShape = {
  documentType: validation.stringRequired,
  imageSrc: validation.stringRequired,
}


export const documentMembersTypeShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  documentList: Yup.array().of(Yup.object().shape(documentListTypeShape))

};

export const validationSchema = Yup.object().shape({
  uploadDocumentsMembers: Yup.array().of(Yup.object().shape(documentMembersTypeShape))
});
