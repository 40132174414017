import {
  AdjustmentIncomeModel,
  CurrentJobModel,
  DetailJobMemberModel,
  IncomeTypeModel,
} from "models";
import i18n from "i18n";
import { calculateSum } from "utils/numbers";

export const hasAnyoneDetailedTypeJob = (
  incomeTypeData: IncomeTypeModel[],
): boolean => {
  return incomeTypeData?.some((m) => {
    const currentJob = i18n.t("no") !== m.currentOrPartJob;
    const hasSeasonalJob = !!JSON.parse(String(m.hasSeasonalJob));
    const isSelfEmployed = !!JSON.parse(String(m.isSelfEmployed));
    const otherIncomeSource = i18n.t("no") !== m.otherIncomeSource;
    return currentJob || hasSeasonalJob || isSelfEmployed || otherIncomeSource;
  });
};

export const getCurrentJobYearlyIncome = (
  currentOrPartJob: CurrentJobModel[],
) => {
  if (!currentOrPartJob?.length) return 0;

  const calculateCurrentJobAnnualIncome = currentOrPartJob.map((i) => {
    const amount = Number(i.amount);
    const hoursPerWeek = Number(i.hoursPerWeek);
    const wage = i.wage;
    let yearlyIncome = 0;

    switch (wage) {
      case i18n.t("hourly"):
        yearlyIncome = amount * hoursPerWeek * 52; // the state multiplies by 52
        break;
      case i18n.t("weekly"):
        yearlyIncome = amount * 52; // The State just multiplies by 52
        break;
      case i18n.t("every_two_weeks"):
        yearlyIncome = amount * 26; // The State multiplies by 26
        break;
      case i18n.t("twice_a_month"):
        yearlyIncome = amount * 2 * 12;
        break;
      case i18n.t("monthly"):
        yearlyIncome = amount * 12;
        break;
      case i18n.t("yearly"):
        yearlyIncome = amount;
        break;
    }
    return yearlyIncome;
  });
  return calculateSum(calculateCurrentJobAnnualIncome);
};

export const calculatePaiMemberAnnualIncome = (
  detailJobMember: DetailJobMemberModel,
  adjustmentsIncomeMembers: AdjustmentIncomeModel[],
) => {
  const currentAdjustmentIncome = adjustmentsIncomeMembers?.find(
    (m) => m.id === detailJobMember.id,
  );
  const adjustmentsIncome = currentAdjustmentIncome?.yearlyAmount ?? 0;
  const currentJobYearlyIncome = getCurrentJobYearlyIncome(
    detailJobMember.currentJobs,
  );

  const seasonJobIncomeList = detailJobMember?.seasonalJobs?.map(
    (s) => s.seasonalIncome ?? 0,
  );
  const seasonJobYearlyIncome = detailJobMember.seasonalJobs?.length
    ? calculateSum(seasonJobIncomeList)
    : 0;

  const selfEmployedYearlyIncome = detailJobMember?.selfEmployment
    ? (detailJobMember.selfEmployment.incomeAmount ?? 0) -
      (detailJobMember?.selfEmployment?.lossAmount ?? 0)
    : 0;
  const expectedIncome =
    currentJobYearlyIncome + seasonJobYearlyIncome + selfEmployedYearlyIncome;
  const projectedAnnualIncome = expectedIncome - adjustmentsIncome ?? 0;

  return {
    expectedIncome,
    adjustmentsIncome,
    projectedAnnualIncome,
  };
};
