import React from "react";
import { FormikErrors } from "formik";
import { Button } from "@mui/material";
import { FormikInputField, FormikSelectField, FormRow } from "components";
import { DocumentItem, UploadDocumentModel } from "models";
import { imageMaxSize } from "constants/imageMaxSize";
import { errorsMessage } from "formUtils/errorMessages";

import {
  ErrorLabel,
  GridContainer,
  GridRowEnd,
  GridRowItem,
  Image,
  RemoveButton,
} from "./UploadDocuments.css";
import { documentTypeOptions } from "./formUtils";

interface TaxFillingRowProps {
  index: number;
  submitCount: number;
  memberDocs: UploadDocumentModel;
  setFieldValue: (field: string, value: string) => void;
  addDocument: (doc: DocumentItem) => void;
  removeDocument: (index: number) => void;
  getUploadImageErrorMessage: (
    errors: FormikErrors<{
      uploadDocumentsMembers: UploadDocumentModel[];
    }>,
    memberIndex: number,
    documentIndex: number,
    submitCount: number,
  ) => string;
  errors: FormikErrors<{ uploadDocumentsMembers: UploadDocumentModel[] }>;
  setFieldError: (field: string, errorMessage: string) => void;
}

export const UploadDocumentRow = ({
  index,
  memberDocs,
  removeDocument,
  addDocument,
  setFieldValue,
  getUploadImageErrorMessage,
  errors,
  submitCount,
  setFieldError,
}: TaxFillingRowProps) => {
  const onChangeUpload = (
    event: React.ChangeEvent<HTMLInputElement>,
    documentIndex: number,
  ) => {
    const file = event.target.files?.[0];
    const imageSrcPath = `uploadDocumentsMembers.${index}.documentList.${documentIndex}.imageSrc`;

    if (file) {
      if (file?.size > imageMaxSize) {
        setFieldError(imageSrcPath, errorsMessage.image_max_size);
        return;
      }

      const reader = new FileReader();
      reader.onload = (e) => {
        const dataUrl = e.target?.result as string;
        setFieldValue(imageSrcPath, dataUrl);
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <React.Fragment key={index}>
      <FormRow>
        <GridContainer>
          <GridRowItem>
            <FormikInputField
              disabled
              fullWidth
              label="employer_name"
              name={`uploadDocumentsMembers.${index}.name`}
            />
          </GridRowItem>
          {memberDocs.documentList.map((document, documentIndex) => {
            const uploadErrorMessage = getUploadImageErrorMessage(
              errors,
              index,
              documentIndex,
              submitCount,
            );
            return (
              <React.Fragment key={documentIndex}>
                <GridRowItem>
                  <FormikSelectField
                    options={documentTypeOptions}
                    label="document_type"
                    name={`uploadDocumentsMembers.${index}.documentList.${documentIndex}.documentType`}
                  />
                </GridRowItem>
                <GridRowItem>
                  <>
                    <input
                      name={`uploadDocumentsMembers.${index}.documentList.${documentIndex}.imageSrc`}
                      type="file"
                      accept="image/*, .pdf"
                      onChange={(e) => onChangeUpload(e, documentIndex)}
                    />
                    {uploadErrorMessage && (
                      <div>
                        <ErrorLabel variant="label">
                          {uploadErrorMessage}
                        </ErrorLabel>
                      </div>
                    )}
                  </>
                </GridRowItem>
                {!!document.imageSrc && (
                  <GridRowItem>
                    <Image
                      src={document.imageSrc}
                      alt={`Document ${documentIndex + 1}`}
                      style={{ maxWidth: "100%", maxHeight: "200px" }}
                    />
                  </GridRowItem>
                )}
                <GridRowEnd>
                  <RemoveButton
                    variant="contained"
                    type="button"
                    onClick={() => removeDocument(documentIndex)}
                  >
                    Remove
                  </RemoveButton>
                </GridRowEnd>
              </React.Fragment>
            );
          })}
        </GridContainer>
      </FormRow>
      <Button
        variant={"contained"}
        type="button"
        onClick={() => addDocument({ documentType: "", imageSrc: "" })}
      >
        Add Document
      </Button>
    </React.Fragment>
  );
};
