import React from "react";
import {
  FormikInputField,
  FormikRadioButtonField,
  FormikSelectField,
  FormRow,
} from "components";

import { taxFillingStatusOptions, yesNoOptions } from "./formUtils";
import { GridContainer, GridRowItem } from "./TextFIllingForm.css";

interface TaxFillingRowProps {
  index: number;
}

export const TaxFillingRow = ({ index }: TaxFillingRowProps) => {
  return (
    <FormRow key={index}>
      <GridContainer>
        <GridRowItem>
          <FormikInputField
            disabled
            label={"name"}
            name={`taxFillingMembers.${index}.name`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikSelectField
            options={taxFillingStatusOptions}
            label="expected_tax_filing_status"
            name={`taxFillingMembers.${index}.taxFillingStatus`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikRadioButtonField
            extraLabel="married_filling_jointly_question"
            options={yesNoOptions}
            name={`taxFillingMembers.${index}.isMarriedFilingJointly`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikInputField
            extraLabel="claim_person_question"
            label=""
            name={`taxFillingMembers.${index}.claimantOfDependent`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikRadioButtonField
            extraLabel="tax_dependent_question"
            options={yesNoOptions}
            name={`taxFillingMembers.${index}.outsideHouseholdTaxDependent`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikRadioButtonField
            extraLabel="tax_dependent_claim_noncustodial_parent_question"
            options={yesNoOptions}
            name={`taxFillingMembers.${index}.isClaimedByNoncustodialParent`}
          />
        </GridRowItem>
      </GridContainer>
    </FormRow>
  );
};
