import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import UploadDocuments from "components/pages/DhsPage/Forms/UploadDocuments/UploadDocuments";
import { FormActions } from "components";
import { UploadDocumentModel } from "models";
import { Container } from "components/pages/DhsPage/Forms/Forms.css";
import { DataFormsContext } from "context/DataFormsContext";

export const DocumentsUploadPage = () => {
  const { data } = useContext(DataFormsContext);
  const { id } = useParams();
  const householdMembers = data?.[2]?.members;
  const uploadDocumentsMembers = householdMembers
    ?.filter((person) => person.id === id)
    .map((member) => ({
      id: member.id,
      name: `${member.firstName} ${member.lastName}`,
      documentList: [],
    }));

  return (
    <Container>
      <FormActions
        title={"upload_documents_title_form"}
        form={
          <UploadDocuments
            onBack={() => console.log("back")}
            defaultValues={uploadDocumentsMembers as UploadDocumentModel[]}
            onSubmit={() => console.log("submit")}
          />
        }
      />
    </Container>
  );
};
