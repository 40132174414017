import React  from "react";
import { GeneralForm } from 'components/pages/DhsPage/Forms/types';

export const  PageFifteen: React.FC<{ data: GeneralForm | null }> = ({ data }) => {
  return (
    <div className="container-fluid pt-24">
      <div className="contentContainer flex flex-col space-y-4">
        <div className='flex justify-end items-center'>
          <p className='text-base'>
            Case Number: {' '}
            {
              <span className='underline'>
               {data ? data?.[1]?.caseNumber : null}
              </span>
            }
          </p>
        </div>
        <div className="mt-3">
          <h2 className="text-base font-bold">
            For certified application counselors, navigators, in-person
            assisters, agents, and brokers only.
          </h2>
          <p className="text-xs">
            Complete this section if you are a certified application counselor,
            navigator, in-person assister, agent or broker filling out this
            application for somebody else.
          </p>
          <table className="mt-4">
            <tbody>
            <tr className="text-xs">
              <td className="h-8" colSpan={parseInt("3")}>
                APPLICATION START DATE (MM/DD/YYYY)
                <br />
                <input type="text" value={data?.[13]?.applicationStartDate ?? ''}/>
              </td>
              <td className="h-8" colSpan={parseInt("9")}>
                NAME OF APPLICANT (First Name, Middle Name, Last Name, Suffix){" "}
                <br />
                <input type="text"  value={data?.[13]?.nameApplicant}/>
              </td>
            </tr>
            <tr className="text-xs">
              <td className="h-8" colSpan={parseInt("10")}>
                NAME OF ASSISTER (First Name, Middle Initial, Last Name,
                Suffix)
                <br />
                <input type="text" value={data?.[13]?.nameAssister}/>
              </td>
              <td className="h-8" colSpan={parseInt("2")}>
                ASSISTER PHONE NUMBER <br />
                <input type="text"  value={data?.[13]?.phoneAssister}/>
              </td>
            </tr>
            <tr className="text-xs">
              <td className="h-8" colSpan={parseInt("10")}>
                ORGANIZATION NAME <br />
                <input type="text"  value={data?.[13]?.organizationName}/>
              </td>
              <td className="h-8" colSpan={parseInt("2")}>
                ASSISTER ID NUMBER
                <br />
                <input type="text"  value={data?.[13]?.idAssister}/>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
