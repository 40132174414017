import styled from "styled-components";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  text-align: center;
  height: 100%;
`;

export const ImageSuccess = styled("img")`
  height: 300px;
  display: flex;
  align-content: center;
  justify-content: center;
  object-fit: contain;
`;

export const ButtonBlock = styled("div")`
  display: flex;
  right: 20px;
  gap: 24px;
  margin-top: 24px;
  justify-content: flex-end;
`;
