import React from 'react';
import { Container, SignInButton, WelcomeText, WelcomeTitle } from './AuthPage.css';
import { useAuthorization } from 'hooks/useAuthorization';

export const AuthPage = () => {
  const { handlers } = useAuthorization();

  return (
    <Container>
      <WelcomeText>
        <WelcomeTitle variant='title'>
          Welcome to Briva's Health
        </WelcomeTitle>
        <WelcomeTitle variant={'smallTitleBold'}>
          Explore our healthcare forms and services by logging in.
        </WelcomeTitle>
      </WelcomeText>
      <SignInButton onClick={handlers.handleSignIn}>Sign In</SignInButton>
    </Container>
  );
};

