import styled, { css } from "styled-components";
import { Button } from "@mui/material";
import { colors } from "styles/colors";

export const WrapperPages = styled.div`
  margin: 2em;
  font-family: sans-serif;
`;

export const WrapperButton = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 30px;
`;

export const Image = styled("img")(() => ({
  maxWidth: "100%",
  display: "block",
}));

export const RemoveButton = styled(Button)(
  () => css`
    display: flex !important;
    color: ${colors.white};
    background: ${colors.error} !important;
    width: max-content;

    @media print {
      opacity: 0;
    }
  `,
);
