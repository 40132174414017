import React from 'react';
import { FieldArray, Formik, FormikErrors } from 'formik';

import { FormActionButtons } from 'components';
import { errorsMessage } from 'formUtils/errorMessages';
import { UploadDocumentModel } from 'models/uploadDocument.model';

import { UploadDocumentRow } from './UploadDocumentRow';
import { validationSchema } from './formUtils';
import { Form, FormContent, MemberRows } from '../Forms.css';

interface UploadDocumentsFormProps {
  onBack: () => void;
  defaultValues: UploadDocumentModel[];
  onSubmit: (values: UploadDocumentsFormType) => void;
}

export interface UploadDocumentsFormType {
  uploadDocumentsMembers: UploadDocumentModel[];
}

export const UploadDocuments = ({ onBack, defaultValues, onSubmit }: UploadDocumentsFormProps) => {

  const getUploadImageErrorMessage = (errors: FormikErrors<{
    uploadDocumentsMembers: UploadDocumentModel[]
  }>, memberIndex: number, documentIndex: number, submitCount: number) => {
    // need to make sure that error required will appear only when we press submit
    // but able show max size error

    // @ts-ignore
    const errorMessage = errors?.uploadDocumentsMembers?.[memberIndex]?.documentList?.[documentIndex]?.imageSrc;
    if (errorMessage === errorsMessage.required) {
      return !!submitCount && errorMessage;
    }
    return errorMessage;

  };

  return (
    <Formik
      initialValues={{ uploadDocumentsMembers: defaultValues }}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        onSubmit(values);
      }}
    >
      {({ values, setFieldValue, handleSubmit, errors, setFieldError, submitCount }) => {
        return <Form onSubmit={handleSubmit} autoComplete='off'>
          <FieldArray name='uploadDocumentsMembers'>
            {() => {
              return <FormContent>
                <MemberRows>
                  {values.uploadDocumentsMembers.map((memberDocs, index) => {
                    return <FieldArray key={index} name={`uploadDocumentsMembers.${index}.documentList`}>
                      {({ push: addDocument, remove: removeDocument }) => {
                        return <UploadDocumentRow
                          key={index}
                          index={index}
                          memberDocs={memberDocs}
                          setFieldValue={setFieldValue}
                          addDocument={addDocument}
                          removeDocument={removeDocument}
                          getUploadImageErrorMessage={getUploadImageErrorMessage}
                          errors={errors}
                          submitCount={submitCount}
                          setFieldError={setFieldError}
                        />;
                      }}
                    </FieldArray>;
                  })}
                </MemberRows>
              </FormContent>;
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack} />
        </Form>;
      }}
    </Formik>
  );
};

export default UploadDocuments;
