import { validation } from "formUtils/validation";
import * as Yup from "yup";

export const paiMemberShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  expectedIncome: validation.numberRequired,
  adjustmentsIncome: validation.numberRequired,
  projectedAnnualIncome: validation.numberRequired,
};

export const validationSchema = Yup.object().shape({
  paiMembers: Yup.array().of(Yup.object().shape(paiMemberShape)),
  isSameTotalIncome: validation.stringNotRequired,
});

export const totalIncomeOptions = [
  {
    label: "pai_option_label_yes",
    value: "true",
  },
  {
    label: "pai_option_label_no",
    value: "false",
  },
];
