import React from 'react';
import {FieldArray, Formik} from "formik";
import {AdjustmentIncomeModel} from 'models';
import {FormActionButtons} from 'components';
import {Form, FormContent, MemberRows} from '../Forms.css';
import {AdjustmentIncomeMemberRow} from './AdjustmentIncomeMemberRow';
import {validationSchema} from './formUtils'

export interface AdjustmentIncomeFormType {
  adjustmentIncomeMembers: AdjustmentIncomeModel[]
}

interface AdjustmentIncomeFormProps {
  onSubmit: (values: AdjustmentIncomeFormType) => void;
  onBack: () => void;
  defaultValues: AdjustmentIncomeModel[];
}

export const AdjustmentIncomeForm = ({onBack, defaultValues, onSubmit}: AdjustmentIncomeFormProps) => {
  return (
    <Formik
      initialValues={{adjustmentIncomeMembers: defaultValues}}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => {
        onSubmit(values)
      }}
    >
      {({values, handleSubmit}) => (
        <Form onSubmit={handleSubmit}  autoComplete="off">
          <FieldArray name="adjustmentIncomeMembers">
            {() => {
              return (
                <FormContent>
                  <MemberRows>
                    {values?.adjustmentIncomeMembers?.map((member, index: number) => (
                      <AdjustmentIncomeMemberRow
                        key={member.id}
                        index={index}
                        adjustmentIncomeMembers={values?.adjustmentIncomeMembers ?? []}
                      />
                    ))}
                  </MemberRows>
                </FormContent>
              );
            }}
          </FieldArray>
          <FormActionButtons onBack={onBack}/>
        </Form>
      )}
    </Formik>
  );
};

