import React, { useContext } from "react";
import i18n from "i18n";
import { IncomeTypeModel } from "models";
import { FormActions } from "components";
import { usePersistentCurrentStep } from "hooks/usePersistentCurrentStep";
import { FollowingQuestionsForm } from "components/pages/DhsPage/Forms/FollowingQuestionsForm";
import { RenewingCoverageFutureForm } from "components/pages/DhsPage/Forms/RenewingCoverageFutureForm";
import { DataFormsContext } from "context/DataFormsContext";
import { PregnancyForm } from "./PregnancyForm";
import { AdjustmentIncomeForm } from "./AdjustmentIncomeForm";
import { TaxFillingForm } from "./TaxFillingForm";
import { DetailsJobForm } from "./DetailsJobForm/DetailsJobMembersForm";
import { ApplicantForm } from "./ApplicantForm";
import { HouseholdMemberListForm } from "./HouseholdMemberListForm";
import { useGetDefaultFormValues } from "./useGetDefaultFormValues";
import { IncomeQuestionForm } from "./IncomeQuestionForm";
import { IncomeTypeForm } from "./IncomeTypeForm";
import { HealthCoverageForm } from "./HealthCoverageForm";
import { PAIForm } from "./PAIForm";
import { ApplicationSubmitted } from "./ApplicationSubmitted";
import { AssisterForm } from "./AssisterForm";
import { hasAnyoneDetailedTypeJob } from "./utils";
import { Container } from "./Forms.css";
import { GeneralForm } from "./types";

interface HouseHoldForms {
  [key: number]: JSX.Element;
}

export const Forms = () => {
  const {data, updateData} = useContext(DataFormsContext);
  const [currentStep, setCurrentStep] = usePersistentCurrentStep(1);
  const {
    defaultApplicantMember,
    defaultHouseholdMembers,
    defaultPregnancyMembers,
    defaultTaxFillingMembers,
    defaultIncomeQuestionMembers,
    defaultIncomeTypeMembers,
    defaultDetailJobMembers,
    defaultAdjustmentIncomeMembers,
    defaultPaiMembers,
    defaultFollowingQuestionsMembers,
    defaultHealthCoverageMembers,
    defaultRenewingCoverage,
    defaultAssisterValues
  } = useGetDefaultFormValues(data);

  const paiFormSubtitle = i18n.t("pai_form_subtitle", {
    nextYear: 2025,
  });

  const paiFormTitle = i18n.t("pai_form_title", {
    nextYear: 2025,
});
  const disableBackButton = currentStep === 1;

  const onSubmit = <T, >(values: T, nextStep = 1, redirectOnly = false) => {
    setCurrentStep((prev) => prev + nextStep);
    if (!redirectOnly) {
      updateData((prevData: any) => ({
        ...prevData,
        [currentStep]: values,
      }));
    }
  };

  const onBack = (stepBack = 1, redirectOnly = false) => {
    setCurrentStep((prev: number) => prev - Number(stepBack));
    if (!redirectOnly) {
      updateData((prevData: GeneralForm | null) => {
        const currentStepString =
          currentStep.toString() as unknown as keyof GeneralForm;
        if (prevData && prevData[currentStepString]) {
          const newData = {...prevData};
          delete newData[currentStepString];
          return newData;
        }
        return prevData;
      });
    }
  };

  const onBackCoverageHealthForm = () => {
    const hasAnyoneIncomeQuestion = data?.[5]?.incomeMembers.some((m) =>
      JSON.parse(String(m.hasIncome)),
    );
    const incomeTypeData = data?.[6]?.incomeTypeMembers;
    const isAnyoneHasIncome = hasAnyoneDetailedTypeJob(incomeTypeData ?? []);
    const hasIncomeQuestionOnly = hasAnyoneIncomeQuestion && !isAnyoneHasIncome;
    const hasIncomeOnly = !hasAnyoneIncomeQuestion && isAnyoneHasIncome;
    let stepToGoBack = 1;

    if (!isAnyoneHasIncome && !hasAnyoneIncomeQuestion) {
      stepToGoBack = 5;
    }
    if (hasIncomeQuestionOnly || hasIncomeOnly) {
      stepToGoBack = 4;
    }
    onBack(stepToGoBack);
  };

  const forms: HouseHoldForms = {
    1: (
      <FormActions
        title="household_info"
        form={
          <ApplicantForm
            defaultValues={defaultApplicantMember}
            disableBackButton={disableBackButton}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        }
      />
    ),
    2: (
      <FormActions
        title="household_member_list"
        form={
          <HouseholdMemberListForm
            defaultValues={defaultHouseholdMembers}
            disableBackButton={disableBackButton}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        }
      />
    ),
    3: (
      <FormActions
        title="pregnancy_form_title"
        form={
          <PregnancyForm
            defaultValues={defaultPregnancyMembers}
            onBack={onBack}
            onSubmit={onSubmit}
          />
        }
      />
    ),
    4: (
      <FormActions
        title="tax_filling_form_title"
        subtitle="tax_filling_form_subtitle"
        form={
          <TaxFillingForm
            onSubmit={onSubmit}
            onBack={onBack}
            defaultValues={defaultTaxFillingMembers}
          />
        }
      />
    ),
    5: (
      <FormActions
        title="income_form_title"
        subtitle="income_form_subtitle"
        form={
          <IncomeQuestionForm
            defaultValues={defaultIncomeQuestionMembers}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        }
      />
    ),
    6: (
      <FormActions
        title="income_type_form_title"
        form={
          <IncomeTypeForm
            defaultValues={defaultIncomeTypeMembers}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        }
      />
    ),
    7: (
      <DetailsJobForm
        incomeTypeMembers={data?.[6]?.incomeTypeMembers as IncomeTypeModel[]}
        defaultValues={defaultDetailJobMembers}
        onSubmit={onSubmit}
        onBack={onBack}
      />
    ),
    8: (
      <FormActions
        title="adjustment_income_form_title"
        subtitle="adjustment_income_form_subtitle"
        form={
          <AdjustmentIncomeForm
            defaultValues={defaultAdjustmentIncomeMembers}
            onSubmit={onSubmit}
            onBack={onBack}
          />
        }
      />
    ),
    9: (
      <FormActions
        title={paiFormTitle}
        subtitle={paiFormSubtitle}
        form={
          <PAIForm
            defaultValues={defaultPaiMembers}
            onSubmit={onSubmit}
            onBack={onBackCoverageHealthForm}
          />
        }
      />
    ),
    10: (
      <FormActions
        title="health_coverage_form_title"
        form={
          <HealthCoverageForm
            defaultValues={defaultHealthCoverageMembers}
            onSubmit={onSubmit}
            onBack={onBackCoverageHealthForm}
          />
        }
      />
    ),
    11: (
      <FormActions
        title="following_question_title_form"
        form={
          <FollowingQuestionsForm
            defaultValues={defaultFollowingQuestionsMembers}
            onSubmit={onSubmit}
            onBack={onBackCoverageHealthForm}
          />
        }
      />
    ),
    12: (
      <FormActions
        title="renewing_coverage_form_title"
        subtitle="renewing_coverage_form_subtitle"
        form={
          <RenewingCoverageFutureForm
            onSubmit={onSubmit}
            onBack={onBack}
            defaultValues={defaultRenewingCoverage}
          />
        }
      />
    ),

    13: (
      <FormActions
        title="assisters_title"
        subtitle="assisters_descriptions"
        form={
          <AssisterForm onBack={onBack} onSubmit={onSubmit} defaultValues={defaultAssisterValues}/>
        }
      />
    ),
    14: (
      <FormActions
        title="application_submit_title"
        subtitle="application_submit_subtitle"
        form={
          <ApplicationSubmitted/>
        }
      />
    ),
  };

  return <Container>{forms[currentStep]}</Container>;
};
