import React, { useContext, useRef } from "react";
import { Button } from "@mui/material";
import { useReactToPrint } from "react-to-print";

import { Loader } from "components";
import { DataFormsContext } from "context/DataFormsContext";
import { PrintablePageWrapper } from "pages/PdfPages/PrintablePageWrapper";
import {
  DocumentsPage,
  PageEight,
  PageEighteen,
  PageEleven,
  PageFive,
  PageFour,
  PageNine,
  PageNineteen,
  PageOne,
  PageSeven,
  PageSeventeen,
  PageSix,
  PageSixteen,
  PageTen,
  PageThirteen,
  PageThree,
  PageTwelve,
  PageTwenty,
  PageTwentyOne,
  PageTwentyTwo,
  PageTwo,
  PageFifteen
} from "./pages";
import { WrapperButton, WrapperPages } from "./PdfPages.css";
import "./pdf.css";

export const PdfPages = () => {
  const { data } = useContext(DataFormsContext);
  const componentRef = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current as HTMLDivElement | null,
  });

  const uploadDocumentsMembers = data?.["14"]?.uploadDocumentsMembers;
  const isAnyoneUploadDocuments = uploadDocumentsMembers?.some(
    (doc) => !!doc.documentList.length,
  );

  if (!data) {
    return <Loader />;
  }

  return (
    <WrapperPages>
      <WrapperButton>
        <Button variant={"contained"} onClick={handlePrint}>
          PRINT
        </Button>
      </WrapperButton>

      <div ref={componentRef}>
        <PrintablePageWrapper children={<PageOne />} />
        <PrintablePageWrapper children={<PageTwo />} />
        <PrintablePageWrapper children={<PageThree data={data} />} />
        <PrintablePageWrapper children={<PageFour data={data} />} />
        <PrintablePageWrapper children={<PageFive data={data} />} />
        <PrintablePageWrapper children={<PageSix data={data} />} />
        <PrintablePageWrapper children={<PageSeven data={data} />} />
        <PrintablePageWrapper children={<PageEight data={data} />} />
        <PrintablePageWrapper children={<PageNine data={data} />} />
        <PrintablePageWrapper children={<PageTen data={data} />} />
        <PrintablePageWrapper children={<PageEleven data={data} />} />
        <PrintablePageWrapper children={<PageTwelve data={data} />} />
        <PrintablePageWrapper children={<PageThirteen data={data} />} />
        <PrintablePageWrapper children={<PageFifteen data={data} />} />
        {isAnyoneUploadDocuments && (
          <PrintablePageWrapper children={<DocumentsPage data={data} />} />
        )}
        <PrintablePageWrapper children={<PageSixteen data={data} />} />
        <PrintablePageWrapper children={<PageSeventeen data={data} />} />
        <PrintablePageWrapper children={<PageEighteen data={data} />} />
        <PrintablePageWrapper children={<PageNineteen />} />
        <PrintablePageWrapper children={<PageTwenty />} />
        <PrintablePageWrapper children={<PageTwentyOne />} />
        <PrintablePageWrapper children={<PageTwentyTwo />} />
      </div>
    </WrapperPages>
  );
};
