import React from "react";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { CaseNumber } from "pages/PdfPages/components";

export const PageTwelve: React.FC<{ data: GeneralForm | null }> = ({
  data,
}) => {
  return (
    <div className="container-fluid mt-10 mb-16 pt-10">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={data?.["1"]?.caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] p-2">
          <table className="table">
            <tbody className="text-xs">
              <tr>
                <td className="" colSpan={parseInt("12")}>
                  If you are in a waiting or probationary period, when could
                  coverage begin (MM/DD/YYYY)? <br />
                  <input type="text" />
                </td>
              </tr>
              <tr>
                <td>
                  LIST THE NAMES OF ANYONE ELSE THAT IS ELIGIBLE FOR COVERAGE
                  FROM THIS JOB. <br />
                  <input type="text" />
                </td>
              </tr>
              <tr>
                <td className="font-bold text-lg bg-[#c7c7c7]">
                  Tell us about the health plan offered by this employer for the
                  employee only.
                </td>
              </tr>
              <tr>
                <td>
                  *Minimum value standard are plans that pay at least 60 percent
                  of allowed costs and cover most inpatient hospital and
                  physician services to meet the "minimum value standard" (see
                  Section 36B(c)(2)(C)(ii) of the Internal Revenue Code of
                  1986).
                </td>
              </tr>
              <tr>
                <td>
                  Does the employer offer a health plan that meets the minimum
                  value standard?* <br />
                  <p className="text-sm space-x-8">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">
                        Yes – fill in the information
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  What is the name of the lowest-cost plan offered only to the
                  employee by the employer?
                  <br />
                  <input type="text" />
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  <p>
                    How much would the employee pay in premiums for this plan if
                    he or she received the maximum discount for not using
                    tobacco or any tobacco cessation program offered?
                    <span className="ml-8">
                      {" "}
                      $
                      <input type="text" className="border-b border-black" />
                    </span>
                  </p>
                  <p className="text-sm space-x-8 mt-4">
                    <span>How often?</span>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Weekly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Every Two weeks</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Twice a month</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Monthly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Quarterly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Yearly</span>
                    </label>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  What change will the employer make for the new plan year (if
                  known)?
                  <br />
                  <span className="flex">
                    <p>
                      {" "}
                      <input type="checkbox" className="mr-2" />
                    </p>{" "}
                    <p>
                      {" "}
                      Employer will not offer health coverage for the employee
                    </p>{" "}
                  </span>
                  <span className="flex mt-2">
                    <p>
                      {" "}
                      <input type="checkbox" className="mr-2" />
                    </p>{" "}
                    <p>
                      Employer will start offering health coverage to employees
                      or change the premium for the lowest-cost plan available
                      only to the employee that meets the minimum value
                      standard. (Premium should reflect discounts for not using
                      tobacco and tobacco cessation programs.)
                    </p>
                  </span>
                  <p>
                    How much would the employee have to pay in premiums for this
                    plan?
                    <span className="ml-8">
                      {" "}
                      $
                      <input type="text" className="border-b border-black" />
                    </span>
                  </p>
                  <p className="text-sm space-x-8 mt-4">
                    <span>How often?</span>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Weekly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Every Two weeks</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Twice a month</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Monthly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Quarterly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Yearly</span>
                    </label>
                  </p>
                  <p>
                    Date of change
                    <span className="ml-2">
                      <input type="text" className="border-b border-black" />
                    </span>
                  </p>
                </td>
              </tr>
              <tr>
                <td className="font-bold text-lg bg-[#c7c7c7]">
                  Tell us about the health plan offered by this employer for the
                  employee only.
                </td>
              </tr>
              <tr>
                <td>
                  *Minimum value standard are plans that pay at least 60 percent
                  of allowed costs and cover most inpatient hospital and
                  physician services to meet the "minimum value standard" (see
                  Section 36B(c)(2)(C)(ii) of the Internal Revenue Code of
                  1986).
                </td>
              </tr>
              <tr>
                <td>
                  Does the employer offer a health plan that meets the minimum
                  value standard?* <br />
                  <p className="text-sm space-x-8">
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">
                        Yes – fill in the information
                      </span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">No</span>
                    </label>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  What is the name of the lowest-cost plan offered only to the
                  employee by the employer?
                  <br />
                  <input type="text" />
                </td>
              </tr>
              <tr>
                <td className="flex flex-col">
                  <p>
                    How much would the employee pay in premiums for this plan if
                    he or she received the maximum discount for not using
                    tobacco or any tobacco cessation program offered?
                    <span className="ml-8">
                      {" "}
                      $
                      <input type="text" className="border-b border-black" />
                    </span>
                  </p>
                  <p className="text-sm space-x-8 mt-4">
                    <span>How often?</span>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Weekly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Every Two weeks</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Twice a month</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Monthly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Quarterly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Yearly</span>
                    </label>
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  What change will the employer make for the new plan year (if
                  known)?
                  <br />
                  <span className="flex">
                    <p>
                      {" "}
                      <input type="checkbox" className="mr-2" />
                    </p>{" "}
                    <p>
                      {" "}
                      Employer will not offer health coverage for the employee
                    </p>{" "}
                  </span>
                  <span className="flex mt-2">
                    <p>
                      {" "}
                      <input type="checkbox" className="mr-2" />
                    </p>{" "}
                    <p>
                      Employer will start offering health coverage to employees
                      or change the premium for the lowest-cost plan available
                      only to the employee that meets the minimum value
                      standard. (Premium should reflect discounts for not using
                      tobacco and tobacco cessation programs.)
                    </p>
                  </span>
                  <p>
                    How much would the employee have to pay in premiums for this
                    plan?
                    <span className="ml-8">
                      {" "}
                      $
                      <input type="text" className="border-b border-black" />
                    </span>
                  </p>
                  <p className="text-sm space-x-8 mt-4">
                    <span>How often?</span>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Weekly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1">Every Two weeks</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Twice a month</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Monthly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Quarterly</span>
                    </label>
                    <label className="inline-flex items-center">
                      <input
                        type="radio"
                        className="form-radio"
                        name="radio"
                        value="2"
                      />
                      <span className="ml-1"> Yearly</span>
                    </label>
                  </p>
                  <p>
                    Date of change
                    <span className="ml-2">
                      <input type="text" className="border-b border-black" />
                    </span>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
