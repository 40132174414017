import i18n from "i18n";
import { LanguageEnum } from "enums";

export const defineLangByValue = (value: string) => {
  const defaultLanguage = sessionStorage.getItem("lang") || LanguageEnum.EN;

  const translatedEachLangValue: any = {
    en: i18n.t(value, { lng: LanguageEnum.EN }),
    es: i18n.t(value, { lng: LanguageEnum.ES }),
    so: i18n.t(value, { lng: LanguageEnum.SO }),
  };
  let lang = defaultLanguage;
  for (lang in translatedEachLangValue) {
    if (translatedEachLangValue[lang] === value) {
      return lang;
    }
  }

  return lang;
};

export const compareTranslatedValue = (
  initialValue: string | undefined = "",
  translationKey: string,
) => {
  return initialValue === i18n.t(translationKey);
};
