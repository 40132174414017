import * as yup from "yup";
import { validation } from "formUtils/validation";
import { LanguageEnum } from "enums";

export const langList = [
  { uuid: LanguageEnum.EN, name: "English" },
  { uuid: LanguageEnum.ES, name: "Spanish" },
  { uuid: LanguageEnum.SO, name: "Somali" },
];
export const countyList = [
  { uuid: "aitkin", name: "aitkin" },
  { uuid: "anoka", name: "anoka" },
  { uuid: "becker", name: "becker" },
  { uuid: "beltrami", name: "beltrami" },
  { uuid: "benton", name: "benton" },
  { uuid: "big_stone", name: "big_stone" },
  { uuid: "blue_earth", name: "blue_earth" },
  { uuid: "brown", name: "brown" },
  { uuid: "carlton", name: "carlton" },
  { uuid: "carver", name: "carver" },
  { uuid: "cass", name: "cass" },
  { uuid: "chippewa", name: "chippewa" },
  { uuid: "chisago", name: "chisago" },
  { uuid: "clay", name: "clay" },
  { uuid: "clearwater", name: "clearwater" },
  { uuid: "cook", name: "cook" },
  { uuid: "cottonwood", name: "сottonwood" },
  { uuid: "crow_wing", name: "crow_wing" },
  { uuid: "crow_wing", name: "crow_wing" },
  { uuid: "dakota", name: "dakota" },
  { uuid: "dept_of_human_services", name: "dept_of_human_services" },
  { uuid: "dept_of_human_services", name: "dept_of_human_services" },
  { uuid: "dodge", name: "dodge" },
  { uuid: "douglas", name: "douglas" },
  { uuid: "faribault", name: "faribault" },
  { uuid: "fillmore", name: "fillmore" },
  { uuid: "freeborn", name: "freeborn" },
  { uuid: "goodhue", name: "goodhue" },
  { uuid: "grant", name: "grant" },
  { uuid: "hennepin", name: "hennepin" },
  { uuid: "houston", name: "houston" },
  { uuid: "hubbard", name: "hubbard" },
  { uuid: "isanti", name: "isanti" },
  { uuid: "itasca", name: "itasca" },
  { uuid: "jackson", name: "jackson" },
  { uuid: "kanabec", name: "kanabec" },
  { uuid: "kandiyohi", name: "kandiyohi" },
  { uuid: "kittson", name: "kittson" },
  { uuid: "koochiching", name: "koochiching" },
  { uuid: "lac_qui_parle", name: "lac_qui_parle" },
  { uuid: "lake", name: "lake" },
  { uuid: "lake_of_the_woods", name: "lake_of_the_woods" },
  { uuid: "le_sueur", name: "le_sueur" },
  { uuid: "lincoln", name: "lincoln" },
  { uuid: "lyon", name: "lyon" },
  { uuid: "mcleod", name: "mcleod" },
  { uuid: "mahnomen", name: "mahnomen" },
  { uuid: "marshall", name: "marshall" },
  { uuid: "martin", name: "martin" },
  { uuid: "meeker", name: "meeker" },
  { uuid: "mille_lacs", name: "mille_lacs" },
  { uuid: "morrison", name: "morrison" },
  { uuid: "mower", name: "mower" },
  { uuid: "mower", name: "mower" },
  { uuid: "murray", name: "murray" },
  { uuid: "nicollet", name: "nicollet" },
  { uuid: "nobles", name: "nobles" },
  { uuid: "norman", name: "norman" },
  { uuid: "olmsted", name: "olmsted" },
  { uuid: "otter_tail", name: "otter_tail" },
  { uuid: "pennington", name: "pennington" },
  { uuid: "pine", name: "pine" },
  { uuid: "pipestone", name: "pipestone" },
  { uuid: "polk", name: "polk" },
  { uuid: "pope", name: "pope" },
  { uuid: "ramsey", name: "ramsey" },
  { uuid: "red_lake", name: "red_lake" },
  {
    uuid: "red_lake_nation_oshkiimaajitahdah",
    name: "red_lake_nation_oshkiimaajitahdah",
  },
  { uuid: "redwood", name: "redwood" },
  { uuid: "renville", name: "renville" },
  { uuid: "rice", name: "rice" },
  { uuid: "rock", name: "rock" },
  { uuid: "roseau", name: "roseau" },
  { uuid: "st_louis", name: "st_louis" },
  { uuid: "scott", name: "scott" },
  { uuid: "sherburne", name: "sherburne" },
  { uuid: "sibley", name: "sibley" },
  { uuid: "stearns", name: "stearns" },
  { uuid: "steele_mn_plairie", name: "steele_mn_plairie" },
  { uuid: "stevens", name: "stevens" },
  { uuid: "swift", name: "swift" },
  { uuid: "todd", name: "todd" },
  { uuid: "traverse", name: "traverse" },
  { uuid: "wabasha", name: "wabasha" },
  { uuid: "wadena", name: "wadena" },
  { uuid: "waseca_mn_plairie", name: "waseca_mn_plairie" },
  { uuid: "washington", name: "washington" },
  { uuid: "watonwan", name: "watonwan" },
  {
    uuid: "white_earth_financial_services",
    name: "white_earth_financial_services",
  },
  { uuid: "wilkin", name: "wilkin" },
  { uuid: "winona", name: "winona" },
  { uuid: "wright", name: "wright" },
  { uuid: "yellow Medicine", name: "yellow Medicine" },
];

export const validationSchema = yup.object().shape({
  caseNumber: validation.caseNumber,
  firstName: validation.stringRequired,
  middleName: validation.stringNotRequired,
  lastName: validation.stringRequired,
  birthday: validation.dateRequired,
  materialStatus: validation.stringRequired,
  phoneNumber: validation.stringRequired,
  otherPhoneNumber: validation.stringNotRequired,
  streetAddress: validation.stringRequired,
  city: validation.stringRequired,
  state: validation.stringRequired,
  zipCode: validation.stringRequired,
  county: validation.stringRequired,

  alternativeStreetAddress: validation.stringNotRequired,
  alternativeCity: validation.stringNotRequired,
  alternativeState: validation.stringNotRequired,
  alternativeZipCode: validation.stringNotRequired,
  alternativeCounty: validation.stringNotRequired,

  email: validation.emailNotRequired,
  allowTextMessage: validation.booleanNotRequired,
});
