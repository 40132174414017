import React from "react";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { CaseNumber } from "pages/PdfPages/components";

export const PageEighteen: React.FC<{ data: GeneralForm | null }> = ({
  data,
}) => {
  return (
    <div className="container-fluid">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={data?.["1"]?.caseNumber} />
        <div className="p-2">
          <div className="flex flex-col">
            <p className="text-end text-2xs">DHS-3741-ENG 12-20</p>
            <h1 className="text-lg font-bold text-center">
              IMPORTANT APPEAL RIGHTS! READ THIS NOW!
            </h1>
          </div>
          <div className="mt-2">
            <h3 className="text-sm font-bold">
              What if I do not agree with the action taken on my health care
              coverage?
            </h3>
            <p className="mb-4 text-xs">
              If you think the decision in your health care notice is wrong, you
              have the right to appeal. An appeal is a legal process where a
              human services judge holds a hearing and reviews (1) a decision by
              the Minnesota Department of Human Services (DHS) about
              MinnesotaCare coverage; or (2) a decision by a county or tribal
              agency about Medical Assistance coverage. You can learn more about
              how this works at www.dhs.state.mn.us/appeals/faqs.
            </p>
            <h3 className="text-sm font-bold">How do I appeal?</h3>
            <p className="mb-2 text-xs">
              You can appeal by submitting your own written request, filling out
              a DHS appeal form, or getting help by phone or in person. The DHS
              Appeals Division or your county or tribal agency can help you file
              your appeal.
            </p>
            <p>Do any of the following to start an appeal:</p>
            <ul className="mb-4 text-xs">
              <li>
                • Log into your account at www.MNsure.org to access the Appeal
                Request Form.
              </li>
              <li>
                • Please fill out the DHS-0033 form at
                https://edocs.dhs.state.mn.us/lfserver/Public/DHS-0033-ENG and
                submit it electronically.
              </li>
              <li>
                • For information on filing an appeal, call your county or
                tribal agency or the DHS Appeals Division at 651-431-3600.
              </li>
              <li>
                • Mail your request to the Minnesota Department of Human
                Services Appeals Division, PO Box 64941, St. Paul, MN
                55164-0941, or fax it to 651-431-7523.
              </li>
              <li>
                • To get help in person, come to the Minnesota Department of
                Human Services Information Desk, 444 Lafayette Road N, St. Paul,
                MN 55155.
              </li>
            </ul>
            <h3 className="text-sm font-bold">What can I appeal?</h3>
            <p className="text-xs">You can appeal any of these:</p>
            <ul className="mb-4 text-xs">
              <li>
                • The county or tribal agency, or DHS failed to act on your
                request about health care coverage.
              </li>
              <li>
                • The county or tribal agency, or DHS processed your request too
                slowly.
              </li>
              <li>
                • The county or tribal agency, or DHS took an action you do not
                agree with (examples of actions: denial of Medical Assistance
                coverage, approval of coverage for a program you do not think
                you are eligible for, a change in your MinnesotaCare benefits).
              </li>
            </ul>
            <h3 className="text-sm font-bold">When must I appeal?</h3>
            <p className="mb-3 text-xs">
              If your appeal involves Medical Assistance or MinnesotaCare, you
              must file your appeal within <strong>30 days</strong> of receiving
              your health care notice. If you show good cause for not appealing
              a Medical Assistance or MinnesotaCare action within{" "}
              <strong>30 days</strong>, you may be able to appeal up to{" "}
              <strong>90 days</strong> after the date of your health care
              notice. See below for more important information about time limits
              for Medical Assistance and MinnesotaCare appeals.
            </p>
            <p className="mb-4 text-xs">
              *Important: An appeal decision for one household member may affect
              the eligibility of other household members. Household eligibility
              may need to be redetermined.
            </p>
            <h3 className="text-sm font-bold">
              Will my benefits continue during my appeal?
            </h3>
            <p className="text-xs">
              You may be able to continue to get the same benefits you were
              receiving at the time you got the health care notice. But you may
              have to file your appeal within a certain time limit:
            </p>
            <p className="pl-4 text-xs">
              For Medical Assistance and MinnesotaCare enrollees, we usually
              must send you an advance notice 10 days or more before the
              effective date of an action, or we may send you a notice five days
              before an action, depending on the situation. Your benefits will
              automatically continue if you file your appeal by the effective
              date of the action on the advance health care notice. In a few
              situations we may send you a notice less than five days before an
              action, or on the effective date of an action. Your benefits will
              continue if you file an appeal within 15 days from the date of
              that health care notice. You must pay your monthly MinnesotaCare
              premium to get continued coverage during your appeal. Tell DHS in
              writing if you do not want your benefits to continue.
            </p>
            <p className="mb-2 text-xs">
              <strong>Important:</strong> If you lose your appeal, you may have
              to pay back the benefits you got while your appeal was pending.
            </p>
            <p className="mb-4 text-xs">
              <strong>Important:</strong> You have the right to apply for
              Medical Assistance or MinnesotaCare again if your benefits stop.
            </p>
            <h3 className="text-sm font-bold mt-40 pt-12">
              What if I need a hearing right away?
            </h3>
            <p className="mb-4 text-xs">
              You have the right to ask for an expedited (sped-up) appeal. If
              you need a hearing right away, tell DHS the reason when you file
              your appeal. To ask for a sped-up appeal for Medical Assistance or
              MinnesotaCare, contact the DHS Appeals Office at 800-657-3510
              (outstate) or 651-431-3600 (metro).
            </p>
            <h3 className="text-sm font-bold">
              What do I do after I file my appeal?
            </h3>
            <p className="mb-3 text-xs">
              Gather information related to the action you are appealing that
              you think will prove or explain the reason you think the action
              was wrong.
            </p>
            <p className="mb-3 text-xs">
              You will get a letter telling you the date and time of the appeal
              hearing. Many hearings are done over the phone.
            </p>
            <p className="text-xs">
              Continue to report changes (such as the start or stop of a job or
              changes in who lives with you) within these time frames:
            </p>
            <ul className="text-xs">
              <li>
                <strong>• 30 days</strong> if you have MinnesotaCare
              </li>
              <li>
                <strong>• 10 days</strong> if you have Medical Assistance
              </li>
            </ul>
            <p className="mb-4 text-xs">
              If you have Medical Assistance, report changes by calling your
              county or tribal agency. If you have MinnesotaCare, report changes
              by calling Health Care Consumer Support at 651-297-3862 or
              800-657-3672.{" "}
            </p>
            <h3 className="text-sm font-bold">
              Can I get help with my appeal?
            </h3>
            <p className="text-xs">
              You may speak for yourself at the hearing. You may also have
              someone else speak for you. You can let us know that you want
              someone else to speak for you at the hearing when you file your
              appeal. If your income is below a certain limit, you may be able
              to get legal advice or help with your appeal from your local legal
              aid office.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
