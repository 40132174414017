import React from "react";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { CaseNumber } from "pages/PdfPages/components";

export const DocumentsPage: React.FC<{ data: GeneralForm | null }> = ({
  data,
}) => {
  const uploadDocumentsMembers = data?.["14"]?.uploadDocumentsMembers;

  return (
    <div className="container-fluid mt-16">
      <div className="contentContainer flex flex-col space-y-4">
        <CaseNumber caseNumber={data?.["1"]?.caseNumber} />
        {uploadDocumentsMembers?.map((member) => {
          return (
            <div>
              <p className="text-sm m-2 space-x-2">NAME: {member.name}</p>
              {member.documentList.map((doc) => (
                <div className="mb-12">
                  <p className="text-sm m-2 space-x-2">
                    DOCUMENT TYPE: {doc.documentType}
                  </p>
                  <img src={doc.imageSrc} alt={doc.documentType} />
                </div>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};
