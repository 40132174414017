import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "@mui/material";
import { Header, Loader } from "components";
import { Auth, Dhs, DocumentsUploadPage, PdfPages } from "pages";
import { theme } from "styles/theme";
import { MainContainer } from "App.css";
import { routes } from "routes";
import { DataFormsProvider } from "context/DataFormsContext";
import { useAuthorization } from "hooks/useAuthorization";

import i18n from "./i18n";

export const App = () => {
  const {variables: {user, isLoading}} = useAuthorization();

  const renderContent = () => {
    if (isLoading) {
      return <Loader/>;
    }
    if (user) {
      return (
        <Routes>
          <Route path={routes.form} element={<Dhs/>}/>
          <Route path={routes.upload} element={<DocumentsUploadPage/>}/>
          <Route path={routes.print} element={<PdfPages/>}/>
        </Routes>
      )
    }
    return <Auth/>;
  };

  return (
    <ThemeProvider theme={theme}>
      <I18nextProvider i18n={i18n}>
        <BrowserRouter>
          <DataFormsProvider>
            <Header/>
            <MainContainer>
              {renderContent()}
            </MainContainer>
          </DataFormsProvider>
        </BrowserRouter>
      </I18nextProvider>
    </ThemeProvider>
  );
};
