import { TextField } from "@mui/material";
import InputMask from "react-input-mask";
import { FormikValues } from "formik";
import { useTranslation } from "react-i18next";
import { useRef } from "react";

interface FormikBirthdayPickerProps {
  formik: FormikValues;
  label: string;
  name: string;
  mask?: string;
}

export const FormikBirthdayPicker = ({
  formik,
  mask = "99/99/9999",
  label,
  name,
}: FormikBirthdayPickerProps) => {
  const { t } = useTranslation();
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <InputMask
      mask={mask as string}
      name={name}
      value={formik.values[name]}
      onChange={formik.handleChange}
      onFocus={(event) => {
        event.target.select();
        inputRef?.current?.focus();
      }}
    >
      {
        // library issue which required ts-ignoring
        // @ts-ignore
        () => {
          return (
            <TextField
              label={t(label)}
              size="small"
              fullWidth
              name={name}
              autoComplete="off"
              error={formik.touched[name] && Boolean(formik.errors[name])}
              helperText={formik.touched[name] && t(formik.errors[name])}
              {...(formik.values[name] && { shrink: true })}
            />
          );
        }
      }
    </InputMask>
  );
};
