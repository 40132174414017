 import {validation} from "formUtils/validation";
import * as Yup from "yup";
import {HouseholdMemberModel} from "models";

export interface HouseholdMembersForm {
  members: HouseholdMemberModel[]
}

export const generateHouseHoldMembersOptions = () => {
  return Array.from({ length: 20 }, (_, index) => ({
    uuid: `${index + 1}`,
    name: `${index + 1}`,
  }));
};

export const memberShape = {
  firstName: validation.stringRequired,
  middleName: validation.stringNotRequired,
  lastName: validation.stringRequired,
  birthday: validation.dateRequired,
  materialStatus: validation.stringRequired,
  relationship: validation.stringRequired,
};

export const validationSchema = Yup.object().shape({
  members: Yup.array().of(Yup.object().shape(memberShape))
});

export const initialMember = {
  firstName: '',
  middleName: '',
  lastName: '',
  birthday: '',
  materialStatus: '',
  relationship: '',
}


