import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "@mui/material";
import { ButtonsSection } from "./FormActionButtons.css";

interface FormActionButtonsProps {
  onBack: () => void;
  disableBackButton?: boolean;
  displayNextButton?: boolean;
  submitButtonType?: "submit" | "button";
  onButtonTypeClick?: () => void;
}

export const FormActionButtons = ({
  onBack,
  disableBackButton,
  displayNextButton = true,
  submitButtonType = "submit",
  onButtonTypeClick,
}: FormActionButtonsProps) => {
  const { t } = useTranslation();
  return (
    <ButtonsSection>
      <Button
        onClick={() => onBack()}
        disabled={disableBackButton}
        size={"large"}
      >
        {t("back")}
      </Button>
      {displayNextButton && (
        <Button
          {...(submitButtonType === "button" && { onClick: onButtonTypeClick })}
          type={submitButtonType}
          size={"large"}
          variant={"contained"}
        >
          {t("next")}
        </Button>
      )}
    </ButtonsSection>
  );
};
