import React from "react";
import {
  FormikBirthdayPickerField,
  FormikInputField,
  FormikSelectField,
  FormRow,
} from "components";
import { RelationshipEnum } from "enums";
import { materialStatusOptions } from "constants/materialStatuses";
import { relationshipOptions } from "constants/relationship";
import { GridRowItem } from "./HouseHoldMemberListForm.css";
import { GridContainer } from "../Forms.css";

interface HouseholdMemberRowProps {
  index: number;
  disabled: boolean;
}

export const HouseholdMemberRow = ({
  index,
  disabled,
}: HouseholdMemberRowProps) => {
  const relationshipOptionsWithApplicant = index
    ? relationshipOptions
    : [
        { uuid: RelationshipEnum.SELF, name: RelationshipEnum.SELF },
        ...relationshipOptions,
      ];
 
  return (
    <FormRow key={index}>
      <GridContainer>
        <GridRowItem>
          <FormikInputField
            disabled={disabled}
            label="first_name"
            name={`members.${index}.firstName`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikInputField
            disabled={disabled}
            label="middle_name"
            name={`members.${index}.middleName`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikInputField
            disabled={disabled}
            label="last_name"
            name={`members.${index}.lastName`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikBirthdayPickerField
            disabled={disabled}
            label="birthday_label"
            name={`members.${index}.birthday`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikSelectField
            disabled={disabled}
            options={materialStatusOptions}
            label="marital_status"
            name={`members.${index}.materialStatus`}
          />
        </GridRowItem>
        <GridRowItem>
          <FormikSelectField
            disabled={disabled}
            options={relationshipOptionsWithApplicant}
            label="relationship_to_you"
            name={`members.${index}.relationship`}
          />
        </GridRowItem>
      </GridContainer>
    </FormRow>
  );
};
