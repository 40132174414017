import React, { useState } from "react";
import { useFormik } from "formik";
import i18n, { t } from "i18next";
import { Grid } from "@mui/material";
import {
  Checkbox,
  FormActionButtons,
  FormikBirthdayPicker,
  Input,
  ManualCheckbox,
  ManualSelect,
  MultipleRadioGroup,
  Select,
} from "components";
import { ApplicantModel } from "models";
import { materialStatusOptions } from "constants/materialStatuses";
import { phoneTypeOptions } from "../IncomeTypeForm/formUtils";
import { usePersistentLang } from "hooks/usePersistentLang";

import { countyList, langList, validationSchema } from "./formUtils";
import { Form, FormContent, GridContainer } from "../Forms.css";
import { ApplicantInfo, GridContainerFirstRow } from "./ApplicantForm.css";

interface ApplicantFormProps {
  disableBackButton: boolean;
  onSubmit: (values: Partial<ApplicantModel>) => void;
  onBack: () => void;
  defaultValues: Partial<ApplicantModel>;
}

export const ApplicantForm = ({
  onSubmit,
  disableBackButton,
  onBack,
  defaultValues,
}: ApplicantFormProps) => {
  const [currentLang, setCurrentLang] = usePersistentLang();
  const [isSameAddress, setIsSameAddress] = useState<boolean>(true);

  const formik = useFormik<Partial<ApplicantModel>>({
    enableReinitialize: true,
    validationSchema: validationSchema,
    initialValues: defaultValues,
    onSubmit: (values: Partial<ApplicantModel>) => {
      onSubmit(values);
    },
  });

  const toggleIsSameAddress = () => {
    setIsSameAddress(!isSameAddress);
  };

  const changeLanguage = async (event: { target: { value: string } }) => {
    const lang = event.target.value;
    setCurrentLang(lang);
    await i18n.changeLanguage(lang);
  };

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormContent>
        <GridContainerFirstRow>
          <Grid item xs={12} md={4}>
            <Input
              type="number"
              label="case_number"
              name="caseNumber"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <ManualSelect
              fullWidth
              label="language"
              value={currentLang}
              onChange={changeLanguage}
              options={langList}
              settingKeyName={"uuid"}
            />
          </Grid>
        </GridContainerFirstRow>
        <ApplicantInfo>
          <GridContainer>
            <Grid item xs={12} md={4}>
              <Input label="first_name" name="firstName" formik={formik} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input label="middle_name" name="middleName" formik={formik} />
            </Grid>
            <Grid item xs={12} md={4}>
              <Input label="last_name" name="lastName" formik={formik} />
            </Grid>
          </GridContainer>
          <GridContainer>
            <Grid item xs={12} md={4}>
              <FormikBirthdayPicker
                label="birthday_label"
                name="birthday"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <Select
                label="marital_status"
                name="materialStatus"
                formik={formik}
                options={materialStatusOptions}
              />
            </Grid>
          </GridContainer>
        </ApplicantInfo>
        <GridContainer>
          <Grid item xs={12} md={6}>
            <MultipleRadioGroup
              formik={formik}
              fieldName="phoneType"
              options={phoneTypeOptions}
            />
            <Input label="phone_number" name="phoneNumber" formik={formik} />
          </Grid>
          <Grid item xs={12} md={6}>
            <MultipleRadioGroup
              formik={formik}
              fieldName="otherPhoneType"
              options={phoneTypeOptions}
            />
            <Input
              label="other_phone_number"
              name="otherPhoneNumber"
              formik={formik}
            />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12} md={4}>
            <Input
              label="street_address"
              name="streetAddress"
              formik={formik}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <Input label="city" name="city" formik={formik} />
          </Grid>
          <Grid item xs={12} md={1.33}>
            <Input label="state" name="state" formik={formik} />
          </Grid>
          <Grid item xs={12} md={1.83}>
            <Input label="zip_code" name="zipCode" formik={formik} />
          </Grid>
          <Grid item xs={12} md={1.83}>
            <Select
              options={countyList}
              label="county"
              name="county"
              formik={formik}
            />
          </Grid>
        </GridContainer>
        {!isSameAddress && (
          <GridContainer>
            <Grid item xs={12} md={4}>
              <Input
                label="mailing_address"
                name="alternativeStreetAddress"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <Input label="city" name="alternativeCity" formik={formik} />
            </Grid>
            <Grid item xs={12} md={1.33}>
              <Input label="state" name="alternativeState" formik={formik} />
            </Grid>
            <Grid item xs={12} md={1.83}>
              <Input
                label="zip_code"
                name="alternativeZipCode"
                formik={formik}
              />
            </Grid>
            <Grid item xs={12} md={1.83}>
              <Input label="county" name="alternativeCounty" formik={formik} />
            </Grid>
          </GridContainer>
        )}
        <GridContainer>
          <Grid item xs={12} md={12}>
            <ManualCheckbox
              label="same_mailing_address"
              name="isSameAddress"
              value={isSameAddress}
              onChange={toggleIsSameAddress}
            />
          </Grid>
        </GridContainer>
        <GridContainer>
          {formik.values.contactViaEmail && (
            <Grid item xs={12} md={12}>
              <Input label="email" name="email" formik={formik} />
            </Grid>
          )}
          <Grid item xs={12} md={12}>
            <Checkbox
              label={t("contact_via_email")}
              name="contactViaEmail"
              formik={formik}
            />
          </Grid>
        </GridContainer>
        <GridContainer>
          <Grid item xs={12} md={12}>
            <Checkbox
              label={t("contact_via_text_message")}
              name={"allowTextMessage"}
              formik={formik}
            />
          </Grid>
        </GridContainer>
      </FormContent>
      <FormActionButtons
        disableBackButton={disableBackButton}
        onBack={onBack}
      />
    </Form>
  );
};
