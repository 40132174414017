import React from "react";
import { useFormik } from "formik";
import {
  FormActionButtons,
  FormikBirthdayPicker,
  Input,
  MultipleRadioGroup,
} from "components";

import { FollowingQuestionsModel } from "models";
import { yesNoOptions } from "./formUtils";
import { Form, FormContent } from "../Forms.css";
import {
  GridContainerQuestions,
  GridItem,
  GridItemCentered,
} from "./FollowingQuestionsForm.css";
import { parseStringToBoolean } from "utils/parse";

interface FollowingQuestionsFormProps {
  defaultValues: FollowingQuestionsModel;
  onSubmit: (values: FollowingQuestionsModel) => void;
  onBack: () => void;
}

export const FollowingQuestionsForm = ({
  defaultValues,
  onSubmit,
  onBack,
}: FollowingQuestionsFormProps) => {
  const formik = useFormik<FollowingQuestionsModel>({
    enableReinitialize: true,
    initialValues: defaultValues,
    onSubmit: (values: FollowingQuestionsModel) => {
      onSubmit(values);
    },
  });

  const isInJail = parseStringToBoolean(formik.values?.isInJail);
  const isNeedPayingMedical = parseStringToBoolean(
    formik.values?.isNeedPayingMedical,
  );
  const hasHealthConditions = parseStringToBoolean(
    formik.values?.hasHealthConditions,
  );
  const isBlindOrDisabled = parseStringToBoolean(
    formik.values?.isBlindOrDisabled,
  );
  const hasDeterminedDisabledSSA = parseStringToBoolean(
    formik.values?.hasDeterminedDisabledSSA,
  );
  const isReceivingSSI = parseStringToBoolean(formik.values?.isReceivingSSI);
  const isNeedHomeCareHelp = parseStringToBoolean(
    formik.values?.isNeedHomeCareHelp,
  );
  const hasOngoingMedicalBills = parseStringToBoolean(
    formik.values?.hasOngoingMedicalBills,
  );
  const isGettingServiceCVT = parseStringToBoolean(
    formik.values?.isGettingServiceCVT,
  );
  const isOutsideOfState = parseStringToBoolean(
    formik.values?.isOutsideOfState,
  );
  const hasReturnedFromMilitaryDutyTour = parseStringToBoolean(
    formik.values?.hasReturnedFromMilitaryDutyTour,
  );

  return (
    <Form onSubmit={formik.handleSubmit}>
      <FormContent>
        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="isInJail"
              formik={formik}
              extraLabel="is_in_jail"
            />
          </GridItemCentered>
          {isInJail && (
            <>
              <GridItemCentered md={4}>
                <MultipleRadioGroup
                  options={yesNoOptions}
                  fieldName="isDispositionOfCharges"
                  formik={formik}
                  extraLabel="is_disposition_of_charges"
                />
              </GridItemCentered>
              <GridItem md={4}>
                <Input label="who" name="whoIsInJail" formik={formik} />
              </GridItem>
            </>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="isNeedPayingMedical"
              formik={formik}
              extraLabel="is_need_paying_medical"
            />
          </GridItemCentered>
          {isNeedPayingMedical && (
            <GridItem md={4}>
              <Input
                label="who"
                name="whoIsNeedPayingMedical"
                formik={formik}
              />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="hasHealthConditions"
              formik={formik}
              extraLabel="has_health_conditions"
            />
          </GridItemCentered>
          {hasHealthConditions && (
            <GridItem md={4}>
              <Input
                label="who"
                name="whoHasHealthConditions"
                formik={formik}
              />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="isBlindOrDisabled"
              formik={formik}
              extraLabel="is_blind_or_disabled"
            />
          </GridItemCentered>
          {isBlindOrDisabled && (
            <GridItem md={4}>
              <Input label="who" name="whoIsBlindOrDisabled" formik={formik} />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="hasDeterminedDisabledSSA"
              formik={formik}
              extraLabel="has_determined_disabled_SSA"
            />
          </GridItemCentered>
          {hasDeterminedDisabledSSA && (
            <GridItem md={4}>
              <Input
                label="who"
                name="whoHasDeterminedDisabledSSA"
                formik={formik}
              />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="isReceivingSSI"
              formik={formik}
              extraLabel="is_receiving_SSI"
            />
          </GridItemCentered>
          {isReceivingSSI && (
            <GridItem md={4}>
              <Input label="who" name="whoIsReceivingSSI" formik={formik} />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="isNeedHomeCareHelp"
              formik={formik}
              extraLabel="is_need_home_care_help"
            />
          </GridItemCentered>
          {isNeedHomeCareHelp && (
            <GridItem md={4}>
              <Input label="who" name="whoIsNeedHomeCareHelp" formik={formik} />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="hasOngoingMedicalBills"
              formik={formik}
              extraLabel="has_ongoing_medical_bills"
            />
          </GridItemCentered>
          {hasOngoingMedicalBills && (
            <GridItem md={4}>
              <Input
                label="who"
                name="whoHasOngoingMedicalBills"
                formik={formik}
              />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={4}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="isGettingServiceCVT"
              formik={formik}
              extraLabel="is_getting_service_CVT"
            />
          </GridItemCentered>
          {isGettingServiceCVT && (
            <GridItem md={4}>
              <Input
                label="who"
                name="whoIsGettingServiceCVT"
                formik={formik}
              />
            </GridItem>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={isOutsideOfState ? 3 : 12}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="isOutsideOfState"
              formik={formik}
              extraLabel="outside_of_state"
            />
          </GridItemCentered>
          {isOutsideOfState && (
            <>
              <GridItem md={3}>
                <Input label="who" name="whoIsOutsideOfState" formik={formik} />
              </GridItem>
              <GridItem md={3}>
                <FormikBirthdayPicker
                  formik={formik}
                  label="date_left"
                  name="dateLeft"
                />
              </GridItem>
              <GridItem md={3}>
                <FormikBirthdayPicker
                  formik={formik}
                  label="expected_return_date"
                  name="expectedReturnDate"
                />
              </GridItem>
            </>
          )}
        </GridContainerQuestions>

        <GridContainerQuestions>
          <GridItemCentered md={isOutsideOfState ? 4 : 12}>
            <MultipleRadioGroup
              options={yesNoOptions}
              fieldName="hasReturnedFromMilitaryDutyTour"
              formik={formik}
              extraLabel="has_returned_from_military_duty_tour"
            />
          </GridItemCentered>
          {hasReturnedFromMilitaryDutyTour && (
            <>
              <GridItem md={4}>
                <Input
                  label="who"
                  name="whoHasReturnedFromMilitaryDutyTour"
                  formik={formik}
                />
              </GridItem>
              <GridItem md={4}>
                <FormikBirthdayPicker
                  formik={formik}
                  label="last_active_date"
                  name="lastActiveDate"
                />
              </GridItem>
            </>
          )}
        </GridContainerQuestions>
      </FormContent>
      <FormActionButtons onBack={onBack} />
    </Form>
  );
};
