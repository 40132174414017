import React from "react";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { CurrentJobModel, DetailJobMemberModel } from "models";
import { CaseNumber, RadioButtons } from "pages/PdfPages/components";
import { compareTranslatedValue } from "utils/lang";

export const PageSix: React.FC<{ data: GeneralForm | null }> = ({ data }) => {
  const hasAnyoneCurrentJob = data?.[7]?.detailJobMembers.find(
    (mj) => mj.currentJobs?.length,
  );

  const renderCurrentJobHeader = (item: DetailJobMemberModel | undefined) => {
    return (
      <>
        <thead>
          <tr>
            <th
              className="text-left font-bold text-base"
              colSpan={parseInt("12")}
            >
              Current job
            </th>
          </tr>
        </thead>
        <tr>
          <td className="text-xs" colSpan={parseInt("12")}>
            <span className="font-bold">
              NAME <br />{" "}
              <input type="text" readOnly value={item?.name ?? ""} />
            </span>
          </td>
        </tr>
      </>
    );
  };

  const renderCurrentJobs = (
    job: CurrentJobModel | undefined,
    index: number,
  ) => {
    return (
      <tbody key={index}>
        <tr>
          <td className="text-xs" colSpan={parseInt("6")}>
            <span className="font-bold">EMPLOYER NAME: </span>
            Write the employer name that appears on your paycheck. <br />{" "}
            <input type="text" readOnly value={job?.employerName ?? ""} />
          </td>
          <td className="text-xs" colSpan={parseInt("6")}>
            <span className="font-bold">
              EMPLOYER IDENTIFICATION NUMBER (EIN) <br />
            </span>
            <input type="text" readOnly value={job?.employerEin ?? ""} />
          </td>
        </tr>
        <tr>
          <td className="text-xs" colSpan={parseInt("6")}>
            <span className="font-bold">
              EMPLOYER STREET ADDRESS <br />{" "}
            </span>
            <input
              type="text"
              readOnly
              value={job?.employerStreetAddress ?? ""}
            />
          </td>
          <td className="text-xs">
            <span className="font-bold">
              CITY <br />{" "}
            </span>
            <input type="text" readOnly value={job?.employerCity ?? ""} />
          </td>
          <td className="text-xs">
            <span className="font-bold">
              STATE <br />{" "}
            </span>
            <input type="text" readOnly value={job?.employerState ?? ""} />
          </td>
          <td className="text-xs">
            <span className="font-bold">
              ZIP CODE <br />{" "}
            </span>
            <input type="text" readOnly value={job?.employerZipCode ?? ""} />
          </td>
        </tr>
        <tr>
          <td className="" colSpan={parseInt("12")}>
            <h2 className="font-bold text-xs">
              Wages and tips before taxes:{" "}
              <span className="font-normal">
                List the amount after pretax payroll deductionsand before taxes.
                Pretax payroll deductions may be for a retiree plan, health
                insurance plan, childcare plan or a parking and transportation
                program. Choose one and fill in the dollar amount. If work hours
                and wages vary, write the total wages expected for the next 12
                months in the "Yearly" box.
              </span>
            </h2>
            <div className="mt-3 text-xs">
              <span className="inline-flex space-x-2">
                <span className="inline-flex w-52 space-x-2">
                  <p>
                    <input
                      type="radio"
                      className="form-radio"
                      name={`wage-${job?.employerEin}`}
                      value="2"
                      defaultChecked={compareTranslatedValue(
                        job?.wage,
                        "hourly",
                      )}
                    />
                  </p>
                  <p>hourly</p>
                </span>
                <span className="w-56 flex items-end">
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        compareTranslatedValue(job?.wage, "hourly")
                          ? job?.amount ?? ""
                          : ""
                      }
                    />
                  </p>
                  <p className="">per hour</p>
                </span>
                <span className="w-auto inline-flex">
                  <p>Hours per week:</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={job?.hoursPerWeek ? job?.hoursPerWeek : ""}
                    />
                  </p>
                </span>
              </span>{" "}
              <span className="inline-flex space-x-2">
                <span className="inline-flex w-52 space-x-2">
                  <p>
                    <input
                      type="radio"
                      className="form-radio"
                      name={`wage-${job?.employerEin ?? ""}`}
                      value="2"
                      defaultChecked={compareTranslatedValue(
                        job?.wage,
                        "weekly",
                      )}
                    />
                  </p>
                  <p>Weekly</p>
                </span>
                <span className="w-auto flex items-end ">
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        compareTranslatedValue(job?.wage, "weekly")
                          ? job?.amount ?? ""
                          : ""
                      }
                    />
                  </p>
                </span>
              </span>{" "}
              <span className="inline-flex space-x-2">
                <span className="inline-flex w-52 space-x-2">
                  <p>
                    {job?.wage.toLocaleLowerCase().split(" ").join("-") ===
                      "every-two-weeks" ?? ""}{" "}
                    <input
                      type="radio"
                      className="form-radio"
                      name={`wage-${job?.employerEin}`}
                      value="2"
                      defaultChecked={compareTranslatedValue(
                        job?.wage,
                        "every_two_weeks",
                      )}
                    />
                  </p>
                  <p>Every two weeks</p>
                </span>
                <span className="w-auto flex items-end">
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        compareTranslatedValue(job?.wage, "every_two_weeks")
                          ? job?.amount ?? ""
                          : ""
                      }
                    />
                  </p>
                </span>
              </span>{" "}
              <span className="inline-flex space-x-2">
                <span className="inline-flex w-52 space-x-2">
                  <p>
                    <input
                      type="radio"
                      className="form-radio"
                      name={`wage-${job?.employerEin ?? ""}`}
                      value="2"
                      defaultChecked={compareTranslatedValue(
                        job?.wage,
                        "twice_a_month",
                      )}
                    />
                  </p>
                  <p>Twice a month</p>
                </span>
                <span className="w-auto flex items-end">
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        compareTranslatedValue(job?.wage, "twice_a_month")
                          ? job?.amount ?? ""
                          : ""
                      }
                    />
                  </p>
                </span>
              </span>{" "}
              <span className="inline-flex space-x-2">
                <span className="inline-flex w-52 space-x-2">
                  <p>
                    <input
                      type="radio"
                      className="form-radio"
                      name={`wage-${job?.employerEin}`}
                      value="2"
                      defaultChecked={compareTranslatedValue(
                        job?.wage,
                        "monthly",
                      )}
                    />
                  </p>
                  <p>Monthly</p>
                </span>
                <span className="w-auto flex items-end">
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        compareTranslatedValue(job?.wage, "monthly")
                          ? job?.amount ?? ""
                          : ""
                      }
                    />
                  </p>
                </span>
              </span>{" "}
              <span className="inline-flex space-x-2">
                <span className="inline-flex w-52 space-x-2">
                  <p>
                    <input
                      type="radio"
                      className="form-radio"
                      name={`wage-${job?.employerEin}`}
                      value="2"
                      defaultChecked={compareTranslatedValue(
                        job?.wage,
                        "yearly",
                      )}
                    />
                  </p>
                  <p>Yearly</p>
                </span>
                <span className="w-auto flex items-end">
                  <p>$</p>
                  <p>
                    <input
                      type="text"
                      readOnly
                      className="border-b border-black"
                      value={
                        compareTranslatedValue(job?.wage, "yearly")
                          ? job?.amount ?? ""
                          : ""
                      }
                    />
                  </p>
                </span>
              </span>
            </div>
          </td>
        </tr>
      </tbody>
    );
  };

  return (
    <div className="container-fluid mt-12">
      <div className="contentContainer flex flex-col space-y-4 mt-12">
        <CaseNumber caseNumber={data?.["1"]?.caseNumber} />
        <div className="array_container border border-gray-700 bg-[#66666631] p-2">
          <div className="p-2 w-auto">
            <h2 className="font-bold text-base">7. Is anyone working a job?</h2>
            <RadioButtons
              items={[
                {
                  checked: !!hasAnyoneCurrentJob,
                  label: " Yes – fill in the information",
                },
                { checked: !hasAnyoneCurrentJob, label: "No" },
              ]}
            />
          </div>
          {hasAnyoneCurrentJob ? (
            data?.["7"]?.detailJobMembers?.map((item, index) => {
              return (
                item?.currentJobs?.length && (
                  <table key={index} className="table bg-white">
                    {renderCurrentJobHeader(item)}{" "}
                    {item.currentJobs.map((job, index) => {
                      return renderCurrentJobs(job, index);
                    })}
                  </table>
                )
              );
            })
          ) : (
            <table key={1} className="table bg-white">
              {renderCurrentJobHeader(undefined)}{" "}
              {renderCurrentJobs(undefined, 1)}
            </table>
          )}
        </div>
      </div>
    </div>
  );
};
