import React, { useMemo } from "react";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { parseStringToBoolean } from "utils/parse";
import { CaseNumber } from "pages/PdfPages/components";
import RadioButtons from "pages/PdfPages/components/RadioButtons/RadioButtons";

export const PageFive: React.FC<{ data: GeneralForm | null }> = ({ data }) => {
  const hasAnyoneIncome = data?.["5"]?.incomeMembers.some((m) =>
    parseStringToBoolean(m.hasIncome),
  );

  const taxFillingMembersWithPlaceholders = useMemo(() => {
    // need to display min 6 rows for members, with current members and fill with empty placeholders
    const membersCount = data?.["4"]?.taxFillingMembers?.length ?? 0;
    const MIN_ROW_LENGTH = 6;
    if (membersCount < MIN_ROW_LENGTH) {
      const existingMembers = data?.["4"]?.taxFillingMembers || [];
      // set null as we do not need display any values, and this case we will render empty row conditionally in tsx
      const taxFillingPlaceholder = null;
      return Array.from(
        { length: MIN_ROW_LENGTH },
        (_, index) => existingMembers[index] || taxFillingPlaceholder,
      );
    }
    return data?.["4"]?.taxFillingMembers;
  }, [data]);

  return (
    <div className="container-fluid">
      <div className="contentContainer flex flex-col space-y-4 mt-24">
        <CaseNumber caseNumber={data?.["1"]?.caseNumber} />
        <div className="array_container border border-gray-400">
          <div className="p-2 bg-[#706f6f3b]">
            <h2 className="font-bold text-base">
              5. Expected tax filing information
            </h2>
            <p className="text-xs ml-4">
              Enter each household member’s expected filing status for the taxes
              you file next year. If the person is not expecting to file taxes
              and will not be claimed as a dependent, enter the expected tax
              filing status as “NonFiler.”
            </p>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="text-center text-xs">Name</th>
                <th className="text-center text-xs">
                  Expected tax <br /> filing status <br />{" "}
                  <span className="text-2xs font-light">
                    (Filer, Dependent, <br /> Non-Filer)
                  </span>
                </th>
                <th className="text-center text-xs">
                  If the person is a <br /> tax filer, is the <br /> person
                  married <br /> filing jointly?
                </th>
                <th className="text-center text-xs">
                  If the person is a <br /> dependent, who <br /> will claim the{" "}
                  <br /> person?
                </th>
                <th className="text-center text-xs">
                  Will the person <br />
                  be a taxbr dependent of <br /> someone outside <br /> the
                  household?
                </th>
                <th className="text-center text-xs">
                  Will the person be <br /> claimed as a tax <br /> dependent by
                  a <br />
                  noncustodial <br /> parent?
                </th>
              </tr>
            </thead>
            <tbody>
              {taxFillingMembersWithPlaceholders?.map((item) => {
                if (!item) {
                  return (
                    <tr className="h-8" key={1}>
                      <td className="text-center text-xs " />
                      <td className="text-center text-xs" />
                      <td className="text-center text-xs" />
                      <td className="text-center text-xs" />
                      <td className="text-center text-xs" />
                      <td className="text-center text-xs" />
                    </tr>
                  );
                }
                return (
                  <tr key={item.id}>
                    <td className="text-center text-xs">{item.name}</td>
                    <td className="text-center text-xs">
                      {item.taxFillingStatus}
                    </td>
                    <td className="text-center text-xs space-x-2">
                      <RadioButtons
                        items={[
                          {
                            checked: !!item.isMarriedFilingJointly,

                            label: "Yes",
                          },
                          {
                            checked:
                              typeof item.isMarriedFilingJointly ===
                                "boolean" && !item.isMarriedFilingJointly,

                            label: "No",
                          },
                        ]}
                      />
                    </td>
                    <td className="text-center text-xs">
                      {item.claimantOfDependent}
                    </td>
                    <td className="text-center text-xs space-x-2">
                      <RadioButtons
                        items={[
                          {
                            checked: parseStringToBoolean(
                              item.outsideHouseholdTaxDependent,
                            ),
                            label: "Yes",
                          },
                          {
                            checked: !parseStringToBoolean(
                              item.outsideHouseholdTaxDependent,
                            ),
                            label: "No",
                          },
                        ]}
                      />
                    </td>
                    <td className="text-center text-xs space-x-2">
                      <RadioButtons
                        items={[
                          {
                            checked: parseStringToBoolean(
                              item.isClaimedByNoncustodialParent,
                            ),
                            label: "Yes",
                          },
                          {
                            checked: !parseStringToBoolean(
                              item.isClaimedByNoncustodialParent,
                            ),
                            label: "No",
                          },
                        ]}
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <table>
          <thead>
            <tr className="text-left">
              <th className="text-base">6. Income</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="text-xs">
                <p>
                  Does anyone have a job, work seasonally, have self-employment
                  income or receive money from any other sources?
                </p>
                <span className="flex space-x-2 mt-2">
                  <label className="inline-flex items-center text-xs">
                    <input
                      type="radio"
                      className="form-radio"
                      name="houseHold"
                      value="Yes"
                      defaultChecked={hasAnyoneIncome}
                    />
                    <span className="ml-1 text-xs">
                      Yes - Go to question 7.
                    </span>
                  </label>
                  <label className="inline-flex items-center text-xs">
                    <input
                      type="radio"
                      className="form-radio"
                      name="houseHold"
                      value="No"
                      defaultChecked={!hasAnyoneIncome}
                    />
                    <span className="ml-1 text-xs">
                      No. No one has income of any kind. – Go to question 13.
                    </span>
                  </label>
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};
