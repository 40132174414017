import { CircularProgress } from '@mui/material';
import React from 'react';
import { Container } from './Loader.css'

export const Loader = () => {
  return (
    <Container>
      <CircularProgress/>
    </Container>
  );
};

