import React from "react";
import { Grid } from "@mui/material";
import {
  FormikBirthdayPickerField,
  FormikInputField,
  FormikRadioButtonField,
  FormRow,
} from "components";
import { GridContainer } from "../Forms.css";
import { GridRowBabyCount, GridRowItem } from "./PregnancyFormRow.css";
import { PregnancyFormType, pregnantQuestionOptions } from "./formUtils";
import { parseStringToBoolean } from "utils/parse";

interface PregnancyFormRowProps {
  index: number;
  values: PregnancyFormType;
}

export const PregnancyFormRow = ({ index, values }: PregnancyFormRowProps) => {
  const isPregnant = parseStringToBoolean(values.pregnancy[index].isPregnant);
  return (
    <FormRow key={index}>
      <GridContainer>
        <GridRowItem>
          <FormikInputField
            disabled
            label={"name"}
            name={`pregnancy.${index}.name`}
          />
        </GridRowItem>
        <Grid item xs={12} md={3} sm={12}>
          <FormikRadioButtonField
            options={pregnantQuestionOptions}
            name={`pregnancy.${index}.isPregnant`}
          />
        </Grid>
        {isPregnant && (
          <>
            <GridRowBabyCount>
              <FormikInputField
                type="number"
                label="pregnancy_babies_count_question"
                name={`pregnancy.${index}.expectedBabiesCount`}
              />
            </GridRowBabyCount>
            <GridRowItem>
              <FormikBirthdayPickerField
                label="due_date"
                name={`pregnancy.${index}.birthday`}
              />
            </GridRowItem>
          </>
        )}
      </GridContainer>
    </FormRow>
  );
};
