import {validation} from "formUtils/validation";
import * as Yup from "yup";
import {CurrentJobEnum} from "enums";

export const currentJobOptions = [
  {uuid: CurrentJobEnum.NO, name: CurrentJobEnum.NO},
  {uuid: CurrentJobEnum.YES_ONE_JOB, name: CurrentJobEnum.YES_ONE_JOB},
  {uuid: CurrentJobEnum.YES_TWO_JOBS, name: CurrentJobEnum.YES_TWO_JOBS},
]

export const otherIncomeSourceOptions = [
  {uuid: 'no', name: 'no'},
  {uuid: 'alimony', name: 'alimony'},
  {uuid: 'interest', name: 'interest'},
  {uuid: 'net_rental_or_royalty_income', name: 'net_rental_or_royalty_income'},
  {uuid: 'retirement_or_pension_payment', name: 'retirement_or_pension_payment'},
  {uuid: 'social_security_benefits', name: 'social_security_benefits'},
  {uuid: 'unemployment', name: 'unemployment'},
  {uuid: 'any_other_payments', name: 'any_other_payments'},
]

export const yesNoOptions = [
  {label: 'yes', value: true},
  {label: 'no', value: false},
]

export const phoneTypeOptions = [
  { value: 'cell', label: 'cell' },
  { value: 'home', label: 'home' },
  { value: 'work', label: 'work' },
];

export const memberTypeShape = {
  id: validation.stringRequired,
  name: validation.stringRequired,
  currentOrPartJob: validation.stringRequired,
  hasSeasonalJob: validation.booleanRequired,
  isSelfEmployed: validation.booleanRequired,
  otherIncomeSource: validation.stringRequired,
};

export const validationSchema = Yup.object().shape({
  incomeTypeMembers: Yup.array().of(Yup.object().shape(memberTypeShape))
});
