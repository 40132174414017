import React from "react";
import { GeneralForm } from "components/pages/DhsPage/Forms/types";
import { parseStringToBoolean } from "utils/parse";
import { CaseNumber } from "pages/PdfPages/components";

export const PageThree: React.FC<{ data: GeneralForm | null }> = ({ data }) => {
  return (
    <div className="pt-16 p-4">
      <div className="flex justify-between">
        <h1 className="text-2xl font-bold w-3/4">
          Minnesota Health Care Programs Renewal for Families, Children and
          Adults
        </h1>
        <div className="contentContainer flex flex-col space-y-4">
          <CaseNumber caseNumber={data?.["1"]?.caseNumber} />
        </div>
      </div>

      <table className="table bg-white text-xs">
        <thead>
          <tr>
            <th
              className="text-left font-bold text-base"
              colSpan={parseInt("12")}
            >
              1a. Name, address and contact information
            </th>
          </tr>
        </thead>

        <tbody>
          <tr>
            <td className="text-xs" colSpan={parseInt("5")}>
              FIRST NAME
              <br />
              <input type="text" readOnly value={data?.[1]?.firstName} />
            </td>
            <td className="text-xs" colSpan={parseInt("2")}>
              MI
              <br />
              {data?.[1]?.middleName && (
                <input
                  type="text"
                  readOnly
                  value={data?.[1]?.middleName ?? ""}
                />
              )}
            </td>
            <td className="text-xs" colSpan={parseInt("5")}>
              LAST NAME
              <br />
              <input type="text" readOnly value={data?.[1]?.lastName ?? ""} />
            </td>
          </tr>
          <tr>
            <td colSpan={parseInt("10")}>
              PHONE NUMBER where we can call you:
              <br />
              <div className="text-xs flex flex-row gap-10 align-middle">
                <p className="pr-4">{data?.[1]?.phoneNumber}</p>
                <div className="flex flex-row">
                  <p className="px-1 flex align-middle">
                    <input
                      type="radio"
                      name="phone"
                      id="cell1"
                      checked={data?.[1]?.phoneType === "cell"}
                      readOnly
                    />
                    <label className="px-1" htmlFor="cell1">
                      Cell
                    </label>
                  </p>
                  <p className="px-1 flex align-middle">
                    <input
                      type="radio"
                      name="phone"
                      id="home1"
                      checked={data?.[1]?.phoneType === "home"}
                      readOnly
                    />
                    <label className="px-1" htmlFor="home1">
                      Home
                    </label>
                  </p>
                  <p className="px-1 flex align-middle">
                    <input
                      type="radio"
                      name="phone"
                      id="work1"
                      checked={data?.[1]?.phoneType === "work"}
                      readOnly
                    />
                    <label className="px-1" htmlFor="work1">
                      Work
                    </label>
                  </p>
                </div>
              </div>
            </td>
            <td colSpan={parseInt("6")}>
              OTHER PHONE NUMBER where we can call you:
              <br />
              <div className="text-xs flex flex-row gap-10 align-middle">
                <p className="pr-4">{data?.[1]?.otherPhoneNumber}</p>
                <div className="flex flex-row">
                  <p className="px-1 flex align-middle">
                    <input
                      type="radio"
                      name="phone_other"
                      id="cell2"
                      checked={data?.[1]?.otherPhoneType === "cell"}
                    />
                    <label className="px-1" htmlFor="cell2">
                      Cell
                    </label>
                  </p>
                  <p className="px-1 flex align-middle">
                    <input
                      type="radio"
                      name="phone_other"
                      id="home2"
                      checked={data?.[1]?.otherPhoneType === "home"}
                    />
                    <label className="px-1" htmlFor="home2">
                      Home
                    </label>
                  </p>
                  <p className="px-1 flex align-middle">
                    <input
                      type="radio"
                      name="phone_other"
                      id="work2"
                      checked={data?.[1]?.otherPhoneType === "work"}
                    />
                    <label className="px-1" htmlFor="work2">
                      Work
                    </label>
                  </p>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="text-xs" colSpan={parseInt("5")}>
              STREET ADDRESS
              <br />
              <span>{data?.[1]?.streetAddress ?? ""}</span>
            </td>
            <td className="text-xs" colSpan={parseInt("4")}>
              CITY
              <br />
              <input type="text" readOnly value={data?.[1]?.city ?? ""} />
            </td>
            <td className="text-xs" colSpan={parseInt("1")}>
              STATE
              <br />
              <input type="text" readOnly value={data?.[1]?.state ?? ""} />
            </td>
            <td className="text-xs" colSpan={parseInt("1")}>
              ZIP CODE
              <br />
              <input type="text" readOnly value={data?.[1]?.zipCode ?? ""} />
            </td>
            <td className="text-xs" colSpan={parseInt("1")}>
              COUNTY
              <br />
              <input type="text" readOnly value={data?.[1]?.county ?? ""} />
            </td>
          </tr>
          <tr>
            <td className="text-xs" colSpan={parseInt("5")}>
              MAILING ADDRESS (if different)
              <br />
              {data?.[1]?.alternativeStreetAddress && (
                <span>{data?.[1]?.alternativeStreetAddress ?? ""}</span>
              )}
            </td>
            <td className="text-xs" colSpan={parseInt("4")}>
              CITY
              <br />
              {data?.[1]?.alternativeCity && (
                <input
                  type="text"
                  readOnly
                  value={data?.[1]?.alternativeCity}
                />
              )}
            </td>
            <td className="text-xs" colSpan={parseInt("1")}>
              STATE
              <br />
              {data?.[1]?.alternativeState && (
                <input
                  type="text"
                  readOnly
                  value={data?.[1]?.alternativeState}
                />
              )}
            </td>
            <td className="text-xs" colSpan={parseInt("1")}>
              ZIP CODE
              <br />
              {data?.[1]?.alternativeZipCode && (
                <input
                  type="text"
                  readOnly
                  value={data?.[1]?.alternativeZipCode}
                />
              )}
            </td>
            <td className="text-xs" colSpan={parseInt("1")}>
              COUNTY
              <br />
              {data?.[1]?.alternativeCounty && (
                <input
                  type="text"
                  readOnly
                  value={data?.[1]?.alternativeCounty}
                />
              )}
            </td>
          </tr>
        </tbody>
      </table>
      <table className="table bg-white text-xs">
        <thead>
          <tr>
            <th
              className="text-left font-bold text-base"
              colSpan={parseInt("12")}
            >
              1b. Contacting you by email or text message
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Can we send you updates and reminders about your case in the
              future? By checking here, you consent to receive electronic
              notifications. DHS and MNsure are not responsible for any charges
              for electronic notifications. It is your responsibility to check
              with your individual carrier, as standard message and data rates
              may apply.
            </td>
          </tr>
          <tr>
            <td>
              <div className={"flex align-middle flex-row gap-1"}>
                May we contact you via email?{" "}
                <input
                  className="ml-2"
                  type="radio"
                  name="email"
                  readOnly
                  checked={parseStringToBoolean(data?.[1]?.contactViaEmail)}
                  id="yes"
                />
                <label htmlFor="yes">Yes – fill in the information </label>
                <input
                  className="ml-2"
                  type="radio"
                  name="email"
                  readOnly
                  checked={!parseStringToBoolean(data?.[1]?.contactViaEmail)}
                  id="no"
                />
                <label htmlFor="no">No</label>
              </div>
              <div className="m-2 px-2 border border-black">
                EMAIL ADDRESS
                <input type="text" value={data?.[1]?.email ?? ""} />
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div className={"flex align-middle flex-row gap-1"}>
                May we contact you via text message?{" "}
                <input
                  className="ml-2"
                  type="radio"
                  name="allowTextMessage"
                  id="yesMessage"
                  readOnly
                  checked={parseStringToBoolean(data?.[1]?.allowTextMessage)}
                />
                <label htmlFor="yesMessage">
                  Yes – fill in the information{" "}
                </label>
                <input
                  className="ml-2"
                  type="radio"
                  name="allowTextMessage"
                  id="noMessage"
                  readOnly
                  checked={!parseStringToBoolean(data?.[1]?.allowTextMessage)}
                />
                <label htmlFor="noMessage">No</label>
              </div>
              <div className="m-2 px-2 border border-black">
                PHONE NUMBER
                <input type="text" value={data?.[1]?.phoneNumber ?? ""} />
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
