import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom'
import { Button } from "@mui/material";
import { routes } from "routes";
import applicationSubmitted from "assets/application_submitted.jpg";
import { ButtonBlock, Container, ImageSuccess, } from "./ApplicationSubmitted.css";

export const ApplicationSubmitted = () => {
  const {t} = useTranslation();
  const navigate = useNavigate()

  const onHomeRedirect = () => {
    navigate(routes.print)
  };
  return (
    <>
      <Container>
        <ImageSuccess
          src={applicationSubmitted}
          alt={"successfully submitted"}
        />
        <span>{t("application_received_text")}</span>
        <span>{t("application_review_text")}</span>
      </Container>
      <ButtonBlock>
        <Button
          fullWidth
          onClick={onHomeRedirect}
          variant="contained"
          size="large"
        >
          View Print Page
        </Button>
      </ButtonBlock>
    </>
  );
};
