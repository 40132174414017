export const colors = {
  lightGrey: '#f2f2f2',
  darkGrey: '#7f7f7f',
  black: '#000000',
  white: '#ffffff',

  primary: '#1f8e98',
  primaryBg: 'rgba(150,219,231,0.1)',

  disabled: 'rgb(240,240,240)',
  disabledText: '#A2A2A2',

  border: '#C4C4C4',
  error: '#D32F2F'

}