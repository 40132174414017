import React from "react";
import { FormikValues } from "formik";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ExtraLabel } from "components";

interface MultipleRadioGroupProps {
  formik: FormikValues;
  fieldName: string;
  options: { value: any; label: string }[];
  extraLabel?: string;
  onChange?: () => void;
}

export const MultipleRadioGroup: React.FC<MultipleRadioGroupProps> = ({
  formik,
  fieldName,
  options,
  extraLabel,
  onChange,
}) => {
  const { t } = useTranslation();
  return (
    <>
      {extraLabel && <ExtraLabel label={extraLabel} />}
      <RadioGroup
        row
        value={formik.values[fieldName] || ""}
        onChange={(event) => {
          onChange?.();
          formik.setFieldValue(fieldName, event.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <FormControlLabel
              key={option.value}
              value={option.value}
              control={<Radio />}
              label={t(option.label)}
            />
          );
        })}
      </RadioGroup>
    </>
  );
};
