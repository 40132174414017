import styled, { css } from 'styled-components';
import { Button, Grid } from '@mui/material';
import { colors } from 'styles/colors';
import { Typography } from 'components/Typography';

export const GridContainer = styled(Grid)(() => ({
  '&.MuiGrid-container': {
    marginTop: '0',
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column'
  }
}));
GridContainer.defaultProps = {
  container: true,
  columnSpacing: 2,
  rowSpacing: 2
};

export const GridRowItem = styled(Grid)(() => ({
  width: '100%'
}));
GridRowItem.defaultProps = {
  item: true,
  xs: 12,
  md: 12
};

export const GridRowEnd = styled(GridRowItem)(() => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end'
}));



export const Image = styled('img')(() => ({
  maxWidth: '100%',
  maxHeight: '200px',
  display: 'block',
}));


export const RemoveButton = styled(Button)(
  () => css`
    display: flex !important;
    color: ${colors.white};
    background: ${colors.error} !important;
  `
);

export const ErrorLabel = styled(Typography)(() => ({
  color: colors.error,
  margin: '0 14px 0 !important',
  paddingTop: '4px'
}))